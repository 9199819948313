<template>
  <div class="deliver-address">
    <section>
      <div class="delivery-info">
        <div class="delivery" key="delivery-inputs">
          <div class="delivery__input">
            <telia-text-input
              :label="t('COMPANY_NAME')"
              :required-error-message="t('FIELD_REQUIRED')"
              :value="address.name"
              @input="address.name = $event.target.value"
              required
            />
          </div>
          <div class="delivery__input">
            <telia-text-input
              :label="t('STREET_ADDRESS')"
              :required-error-message="t('FIELD_REQUIRED')"
              :value="address.address"
              @input="address.address = $event.target.value"
              maxlength="33"
            />
          </div>
          <div class="delivery__input">
            <telia-text-input
              :label="t('POSTAL_NUMBER')"
              :required-error-message="t('FIELD_REQUIRED')"
              :value="address.zipCode"
              @input="address.zipCode = $event.target.value"
              required
              pattern="^\d+$"
              :pattern-error-message="t('PATTERN_ERROR')"
              minlength="5"
              :minlength-error-message="t('MIN_LENGTH')"
              maxlength="5"
            />
          </div>
          <div class="delivery__input">
            <telia-text-input
              :label="t('CITY')"
              :required-error-message="t('FIELD_REQUIRED')"
              :value="address.city"
              @input="address.city = $event.target.value"
              required
            />
          </div>
        </div>
      </div>
      <div class="page-alert-wrapper">
        <page-alert v-if="streetAddressExceedsMaxLength" class="address-page-alert">
          {{ t("STREET_ADDRESS_EXCEEDS_MAX_LENGTH") }}
        </page-alert>
      </div>
    </section>
  </div>
</template>

<script>
import PageAlert from "./ui-components/page-alert.vue";
import { translateMixin } from "../locale";
import { useVuelidate } from "@vuelidate/core";
import { required, numeric, minLength, maxLength } from "@vuelidate/validators";

export default {
  name: "DeliveryAddress",
  mixins: [translateMixin],
  props: {
    selectedCustomer: {
      type: Object,
      required: true,
    },
  },
  components: {
    PageAlert,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      address: {
        name: "",
        address: "",
        zipCode: "",
        city: "",
      },
    };
  },
  mounted() {
    this.emitValidity(this.valid);
    this.address = {
      name: this.selectedCustomer.name || "",
      address: this.selectedCustomer.address || "",
      zipCode: this.selectedCustomer.zipCode || "",
      city: this.selectedCustomer.city || "",
    };
  },
  computed: {
    valid() {
      return !this.v$.$invalid;
    },
    validDeliveryAddress() {
      return !this.v$.address.$invalid;
    },
    streetAddressExceedsMaxLength() {
      return this.address.address?.length > 33;
    },
  },
  methods: {
    emitValidity(valid) {
      this.$emit("valid", valid);
    },
  },
  watch: {
    address: {
      deep: true,
      handler(address) {
        this.$emit("setDeliveryAddress", address);
      },
    },
    valid: "emitValidity",
  },
  validations: {
    address: {
      name: {
        required,
      },
      address: {
        maxLength: maxLength(33),
      },
      zipCode: {
        required,
        numeric,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
      city: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
.delivery {
  padding-bottom: $telia-spacing-24;
  &__input {
    margin-bottom: $telia-spacing-24;
    max-width: 42rem;
  }
  .address-page-alert {
    max-width: fit-content;
  }
}
</style>
