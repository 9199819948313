<template>
  <div t-id="simcard-selector-existing-sim">
    <telia-p>
      {{ t("EXISTING_SIMCARD_TEXT") }}
    </telia-p>

    <telia-p>
      {{ t("SIM_ISSUED_BY") }}
    </telia-p>

    <telia-p>
      {{ t("EXISTING_SIMCARD_ENTER_ICC_TEXT") }}
    </telia-p>

    <div class="icc-validator">
      <form aria-label="form-wrapper">
        <telia-text-input
          required
          :label="inputPlaceholder"
          @input="(event) => validateIcc(event.target.value)"
          :error-message="errorMessage"
          :additional="validationMessage"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { ValidateIcc } from "@telia/b2b-icc-handler";
import { translateMixin } from "../../locale";

export default {
  name: "simcard-selector-existing-sim",
  mixins: [translateMixin],
  props: {
    iccPrefix: { type: String, default: "894601" },
  },
  data: () => ({
    iccNumber: "",
    valid: false,
    errorMessage: "",
    validationMessage: null,
    checkingIcc: false,
    iccHandlerInstance: null,
  }),
  computed: {
    inputPlaceholder() {
      return `${this.iccPrefix}xxxxxxxxxxxxxx`;
    },
  },
  mounted() {
    this.emitValidity();
    this.emitValues();
    this.iccHandlerInstance = new ValidateIcc(this.iccPrefix, 20);
  },
  methods: {
    validateIcc(event) {
      this.iccNumber = event;
      this.validateIccNumber(event);
    },
    validateIccNumber(number) {
      this.checkingIcc = true;
      this.validationMessage = null;
      this.iccHandlerInstance.verifyIcc(number).then((value) => {
        this.errorMessage = value.message;
        this.valid = false;

        if (value.isError === false) {
          this.errorMessage = null;
          this.validationMessage = value.message;
          this.valid = true;
          this.emitValidity();
          this.emitValues();
        }
      });
      this.checkingIcc = false;
    },
    emitValues() {
      this.$emit("setSimConfig", {
        iccNumber: this.iccNumber,
      });
    },
    emitValidity() {
      this.$emit("valid", this.valid);
    },
  },
};
</script>

<style lang="scss" scoped>
.icc-validator {
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 30rem;
}
</style>
