<template>
  <section t-id="simcard-selector-e-sim">
    <telia-p>
      <strong>{{ t("ESIM_SIMCARD_ACTIVATION") }}</strong>
    </telia-p>
    <telia-p>
      {{ t("ESIM_SIMCARD_ACTIVATION_DESCRIPTION") }}
    </telia-p>
    <div class="radio-button-column">
      <telia-radio-button
        value="me"
        :disabled="disableSendToMe"
        :checked="sendEmailTo === 'me'"
        @input="sendEmailTo = $event.target.value"
      >
        <span>
          {{
            t("ESIM_SIMCARD_QRCODE_EMAIL_ME", {
              emailAddress: customerEmailAddress,
            })
          }}
        </span>
      </telia-radio-button>
      <telia-radio-button
        value="emailAddress"
        :checked="sendEmailTo === 'emailAddress'"
        @input="sendEmailTo = $event.target.value"
      >
        {{ t("ESIM_SIMCARD_QRCODE_EMAIL_CUSTOM") }}
      </telia-radio-button>
    </div>
    <telia-text-input
      v-if="sendEmailTo === 'emailAddress'"
      class="text-input"
      type="email"
      :label="t('EMAIL_ADDRESS')"
      :value="deliveryEmailAddress"
      :required-error-message="emailAddressValidationMessage"
      :additional="t('EMAIL_ADDRESS_PLACEHOLDER')"
      @input="deliveryEmailAddress = $event.target.value"
      required
    />
  </section>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { email, required, requiredIf } from "@vuelidate/validators";
import { getLoggedInUserInfo } from "@telia/b2b-logged-in-service";
import { translateMixin } from "../../locale";

export default {
  name: "simcard-selector-e-sim",
  mixins: [translateMixin],
  validations: {
    sendEmailTo: {
      required,
    },
    deliveryEmailAddress: {
      required: requiredIf((model) => model.sendEmailTo === "emailAddress"),
      email,
    },
    customerEmailAddress: {
      required: requiredIf((model) => model.sendEmailTo === "me"),
      email,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: () => ({
    sendEmailTo: "me",
    customerEmailAddress: "",
    deliveryEmailAddress: "",
    disableSendToMe: false,
  }),
  created() {
    try {
      this.getUserData();
    } catch (error) {
      console.error(error);
    }
  },
  mounted() {
    this.emitValidity(this.valid);
    this.emitValues();
  },
  computed: {
    emailAddressValidationMessage() {
      if (!this.v$.deliveryEmailAddress.required) {
        return this.t("EMAIL_ADDRESS_REQUIRED");
      }

      if (!this.v$.deliveryEmailAddress.email) {
        return this.t("EMAIL_ADDRESS_INVALID_FORMAT");
      }

      return undefined;
    },
    valid() {
      return !this.v$.$error && !this.v$.$invalid;
    },
  },
  methods: {
    emitValues() {
      this.$emit("setSimConfig", {
        deliveryEmailAddress:
          this.sendEmailTo === "emailAddress"
            ? this.deliveryEmailAddress
            : this.customerEmailAddress,
      });
    },
    emitValidity(valid) {
      this.$emit("valid", valid);
    },
    async getUserData() {
      try {
        const currentUserDetails = await getLoggedInUserInfo();
        this.customerEmailAddress = currentUserDetails.mail;
        if (!this.customerEmailAddress) {
          this.sendEmailTo = "emailAddress";
          this.disableSendToMe = true;
        }
      } catch (error) {
        this.sendEmailTo = "emailAddress";
        this.disableSendToMe = true;
      }
    },
  },
  watch: {
    deliveryEmailAddress() {
      this.emitValues();
    },
    valid: "emitValidity",
    sendEmailTo: {
      handler(val) {
        if (val === "me") {
          this.deliveryEmailAddress = this.customerEmailAddress;
        } else if (val === "emailAddress") {
          this.deliveryEmailAddress = "";
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms, transform 200ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(calc(var($telia-spacing-12) * -1));
}

.radio-button-column {
  display: flex;
  flex-direction: column;
  margin-top: $telia-spacing-12;
  margin-bottom: $telia-spacing-24;

  & > telia-radio-button {
    margin-top: $telia-spacing-8;
  }

  & > telia-radio-button:first-child {
    margin-top: 0;
  }
}

.text-input {
  max-width: 30rem;
  margin-top: $telia-spacing-24;
}
</style>
