<template>
  <div t-id="product-configuration-invoice">
    <collapser
      id="new-invoice-group"
      input-group="invoice-selector"
      :title="t('NEW_INVOICE_GROUP')"
      :subtitle="t('NEW_INVOICE_CREATED_AUTOMATICALLY')"
      :selected="selectedChoice === 'new-invoice-group'"
      :aria-expanded="selectedChoice === 'new-invoice-group'"
      :aria-label="t('ALLY.LABEL.NEW_INVOICE_GROUP')"
      @expandCollapser="changeSelectedChoice($event)"
    >
      <new-billing-account
        ref="newBillingAccount"
        @setNewAccount="newAccount = $event"
        :scopeId="scopeId"
        :selected-customer="selectedCustomer"
        :is-new-invoice-group="selectedChoice === 'new-invoice-group'"
      />
    </collapser>

    <collapser
      id="existing-invoice-group"
      input-group="invoice-selector"
      :title="t('EXISTING_INVOICE_GROUP')"
      :subtitle="t('SELECT_EXISTING_INVOICE_ACCOUNT')"
      :selected="selectedChoice === 'existing-invoice-group'"
      :aria-expanded="selectedChoice === 'existing-invoice-group'"
      :aria-label="t('ALLY.LABEL.EXISTING_INVOICE_GROUP')"
      @expandCollapser="changeSelectedChoice($event)"
      :disabled="disableExistingBillingAccounts"
    >
      <div>
        <b2x-spinner v-if="fetchState.status === Status.Pending" class="loader" size="small" />
        <page-alert v-if="fetchState.status === Status.Rejected">
          {{ fetchState.message }}
        </page-alert>
        <select-billing-account
          v-if="fetchState.status === Status.Resolved"
          ref="selectBillingAccount"
          :billing-accounts="billingAccounts"
          :invoice-has-prepaid-subscription="invoiceHasPrepaidSubscription"
          @billing-account-selected="handleAccountSelection($event)"
          :tscid="tscid"
          :scope-id="scopeId"
        />
      </div>
    </collapser>
  </div>
</template>

<script>
import { corpBillingAccounts } from "@telia/b2b-rest-client";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { translateMixin } from "../../locale";
import SelectBillingAccount from "./select-billing-account.vue";
import NewBillingAccount from "./new-billing-account.vue";
import PageAlert from "../ui-components/page-alert.vue";
import Collapser from "../ui-components/collapser.vue";
import "@telia/b2x-spinner";

const Status = Object.freeze({
  Idle: 0,
  Pending: 1,
  Resolved: 2,
  Rejected: 3,
});

export default {
  name: "invoice",
  mixins: [translateMixin],
  components: {
    Collapser,
    PageAlert,
    SelectBillingAccount,
    NewBillingAccount,
  },
  props: {
    tscid: {
      type: String,
      required: true,
    },
    scopeId: {
      type: String,
      required: true,
    },
    selectedCustomer: {
      type: Object,
      required: true,
    },
    invoiceHasPrepaidSubscription: {
      type: Boolean,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      Status,
      selectedChoice: "new-invoice-group",
      accountNumber: "",
      accountReference: "",
      accountAddress: {},
      newAccount: {},
      billingAccounts: [],
      fetchState: {
        status: Status.Idle,
        message: "",
      },
      disableExistingBillingAccounts: false,
    };
  },
  async created() {
    this.setState(Status.Pending);
    try {
      const response = await corpBillingAccounts.BillingAccountsControllerService.getBillingAccountsForTscid(
        this.scopeId,
        this.tscid
      );

      this.billingAccounts = response.filter(
        (billingAccount) =>
          billingAccount.customerNumber === this.selectedCustomer.customerNumber &&
          billingAccount?.singleSubscriptionBilling !== true
      );
    } catch {
      this.emitConfiguration();
      this.emitValidity();
      this.setState(Status.Rejected, this.t("COULD_NOT_FETCH_BILLING_ACCOUNTS"));
      return;
    }

    this.billingAccounts.forEach((account) => {
      const { accountNumber, accountReference, subscriptionCount } = account;
      account.displayName =
        accountNumber +
        (accountReference ? " - " + accountReference : "") +
        ` (${subscriptionCount} ${this.t("SUBSCRIPTIONS")})`;
    });

    if (this.billingAccounts.length === 0) {
      this.disableExistingBillingAccounts = true;
      this.selectedChoice = "new-invoice-group";
    }

    this.emitConfiguration();
    this.emitValidity();
    this.setState(Status.Resolved);
  },
  computed: {
    valid() {
      if (this.selectedChoice === "existing-invoice-group") {
        return !!this.accountNumber;
      } else {
        return this.isNewAccountValid;
      }
    },
    isNewAccountValid() {
      if (Object.keys(this.newAccount).length === 0) {
        return false;
      }
      const { address, postalCode, city } = this.newAccount;
      const isInvalid = !address || address.length > 33 || !postalCode || !city;
      return !isInvalid;
    },
  },
  methods: {
    handleAccountSelection(account) {
      if (!account) {
        this.accountNumber = "";
        this.billingGroup = "";
        this.accountReference = "";
        this.accountAddress = {};
      } else {
        this.accountNumber = account.number;
        this.billingGroup = account.accountNumber;
        this.accountReference = account.accountReference;
        this.accountAddress = {
          name: account.name,
          address: account.address,
          zipCode: account.postalCode,
          city: account.city,
        };
      }
      this.emitConfiguration();
      this.emitValidity();
    },
    changeSelectedChoice(event) {
      this.selectedChoice = event;
      this.$refs.newBillingAccount.clearInput();
      this.$emit("selected-choice", event);
    },
    emitConfiguration() {
      const isNewInvoiceGroup = this.selectedChoice === "new-invoice-group";
      if (!isNewInvoiceGroup) {
        const selectedAccount = {
          type: this.selectedChoice,
          number: this.accountNumber,
          billingGroup: this.billingGroup,
          reference: this.accountReference,
          address: { ...this.accountAddress },
        };
        this.$emit("select-account", selectedAccount);
        this.$emit("new-account", null);
      } else {
        this.$emit("new-account", this.newAccount);
        this.$emit("select-account", null);
      }
    },
    emitValidity() {
      this.$emit("valid", this.valid);
    },
    setState(status, message = "") {
      this.fetchState.status = status;
      this.fetchState.message = message;
    },
  },
  validations: {
    accountNumber: {
      required,
    },
  },
  watch: {
    selectedChoice(val) {
      if (val === "new-invoice-group") {
        this.$refs.newBillingAccount.populateAddress();
      }

      this.emitConfiguration();
      this.emitValidity();
    },
    newAccount: {
      deep: true,
      handler() {
        this.emitConfiguration();
        this.emitValidity();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms, transform 200ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(calc(var(--spacing) * -1));
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
