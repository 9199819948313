<template>
  <div t-id="simcard-selector" class="simcard-selector">
    <collapser
      id="new-sim"
      input-group="simcard-selector"
      :title="t('NEW_SIMCARD_TITLE')"
      :subtitle="t('NEW_SIMCARD_SUBTITLE')"
      :selected="selected === 'new-sim'"
      :aria-expanded="selected === 'new-sim'"
      :aria-label="t('ALLY.LABEL.NEW_SIMCARD')"
      @expandCollapser="selected = $event"
    ></collapser>
    <collapser
      id="existing-sim"
      input-group="simcard-selector"
      :title="t('EXISTING_SIMCARD_TITLE')"
      :subtitle="t('EXISTING_SIMCARD_SUBTITLE')"
      :selected="selected === 'existing-sim'"
      :aria-expanded="selected === 'existing-sim'"
      :aria-label="t('ALLY.LABEL.EXISTING_SIMCARD')"
      @expandCollapser="selected = $event"
    >
      <existing-sim
        ref="existing-sim"
        @setSimConfig="updateConfiguration($event)"
        @valid="validState['existing-sim'] = $event"
      />
    </collapser>
    <collapser
      v-if="!hideEsim"
      id="new-esim"
      input-group="simcard-selector"
      :title="t('ESIM_SIMCARD_TITLE')"
      :subtitle="t('ESIM_SIMCARD_SUBTITLE')"
      :selected="selected === 'new-esim'"
      :aria-expanded="selected === 'new-esim'"
      :aria-label="t('ALLY.LABEL.ESIM')"
      @expandCollapser="selected = $event"
    >
      <e-sim
        ref="new-esim"
        @setSimConfig="updateConfiguration($event)"
        @valid="validState['new-esim'] = $event"
      />
    </collapser>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { translateMixin } from "../../locale";
import ESim from "./e-sim.vue";
import ExistingSim from "./existing-sim.vue";
import Collapser from "../ui-components/collapser.vue";

export default {
  name: "simcard-selector",
  mixins: [translateMixin],
  components: {
    Collapser,
    ESim,
    ExistingSim,
  },
  props: {
    hideEsim: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: () => ({
    selected: "new-sim",
    configuration: {
      type: "SIMCARD",
      iccNumber: null,
      deliveryEmailAddress: null,
    },
    validState: {
      "new-sim": true,
      "existing-sim": false,
      "new-esim": false,
    },
  }),
  mounted() {
    this.updateConfiguration();

    this.$nextTick(() => {
      this.emitValidity(this.valid);
      this.emitConfiguration(this.configuration);
    });
  },
  methods: {
    updateConfiguration(configuration = {}) {
      const isEsimSelected = this.selected === "new-esim";

      const type = isEsimSelected ? "ESIM" : "SIMCARD";

      const iccNumber =
        this.selected === "existing-sim" ? (configuration || {}).iccNumber || null : null;

      const deliveryEmailAddress = isEsimSelected
        ? (configuration || {}).deliveryEmailAddress || null
        : null;

      this.configuration = {
        component: this.selected,
        type,
        iccNumber,
        deliveryEmailAddress,
      };

      return this.configuration;
    },
    emitConfiguration(configuration) {
      this.$emit("setSimcard", configuration);
    },
    emitValidity(valid) {
      this.$emit("valid", valid);
    },
  },
  computed: {
    valid() {
      return this.validState[this.selected];
    },
  },
  watch: {
    selected() {
      this.updateConfiguration();

      const $ref = this.$refs[this.selected];
      if ($ref && $ref.emitValues) {
        $ref.emitValues();
      }
    },
    configuration: "emitConfiguration",
    valid: "emitValidity",
  },
  validations: {
    configuration: {
      type: {
        required,
      },
      iccNumber: {
        required: requiredIf(function () {
          return this.selected === "existing-sim";
        }),
      },
      deliveryEmailAddress: {
        required: requiredIf(function () {
          return this.selected === "e-sim";
        }),
      },
    },
  },
};
</script>

<style lang="scss">
.simcard-selector .checkout-choice {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
</style>
