import { frontendLoggerService } from "@telia/b2x-rest-client";

async function logInfo(message) {
  try {
    await frontendLoggerService.FrontendLoggerControllerService.logUsingPost({
      logLevel: "INFO",
      message: "b2b-order-mobile-subscription" + " -- " + message,
      title: "NTSE Frontend", // distinguish from "Mitt Telia", "Common frontend analytics", etc
    });
  } catch {
    //could not log
  }
}

async function logError(message) {
  try {
    await frontendLoggerService.FrontendLoggerControllerService.logUsingPost({
      logLevel: "ERROR",
      message: "b2b-order-mobile-subscription" + " -- " + message,
      title: "NTSE Frontend", // distinguish from "Mitt Telia", "Common frontend analytics", etc
    });
  } catch {
    //could not log
  }
}

export { logInfo, logError };
