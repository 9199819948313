<template>
  <b2b-layout
    v-if="states.loading.status !== Status.Pending"
    :heading="getOrderFlowTitle"
    :page-title="getOrderFlowTitle"
    show-back-link
    :back-link-label="t('BACK_TO_PRODUCT_AND_SERVICES')"
    :back-link-href="`/foretag/mybusiness/${scopeId}/bestall/produkter-tjanster`"
    :organisation-selector-disabled="true"
    show-organisation-selector
    :selected-organisation="selectedOrganisation.tscid"
    @organisationSelected="handleOrganisationChange($event.detail)"
  >
    <span slot="header" v-if="temporaryIssues">
      <telia-grid>
        <telia-row>
          <telia-col width="12">
            <page-alert t-id="alert" class="issues-notification">
              <telia-p>
                {{ t("TECHNICAL_ISSUES") }}
              </telia-p>
            </page-alert>
          </telia-col>
        </telia-row>
      </telia-grid>
    </span>
    <section>
      <b2x-drawer
        drawer-id="product-configuration-drawer"
        width="md"
        position="right"
        :heading="selectedProduct ? selectedProduct.name : ''"
        :is-open="showProductConfigurationModal"
        @drawerClose="hideModal"
      >
        <div v-show="showProductConfigurationModal">
          <product-configuration
            v-if="showProductConfigurationModal"
            :tscid="selectedOrganisation.tscid"
            :scope-id="scopeId"
            :selected-organisation="selectedOrganisation"
            :selected-customer="selectedCustomer"
            :selected-agreement="selectedAgreement"
            :selected-product="selectedProduct"
            :org-has-nf-agreement="orgHasNfAgreement"
            :is-telia-admin="isTeliaAdmin"
            :agreement-group="agreementGroup"
            @isSubmitting="isSubmitting = $event"
            @hide-modal="hideModal()"
          />
        </div>
      </b2x-drawer>
    </section>

    <telia-grid class="order-mobile-subscription" t-id="order-mobile-subscription">
      <telia-row v-if="states.loading.status === Status.Rejected" key="setup-error">
        <telia-col width="12">
          <page-alert t-id="alert" class="page-alert-wrapper">
            <telia-p>{{ states.loading.message }}</telia-p>
          </page-alert>
        </telia-col>
      </telia-row>
      <telia-row v-else class="scope-selector" t-id="scope-selector">
        <telia-col width="12">
          <telia-skeleton
            v-if="states.loading.status === Status.Pending"
            class="skeleton__customer-selector"
          />
          <div v-else class="inline-wrapper">
            <telia-p>
              {{ t("CUSTOMER_SECTION") }}
            </telia-p>
            <b2x-inline-dropdown
              t-id="customer-selector"
              class="scope-selector__dropdown"
              heading-element="telia-p"
              heading-variant="paragraph-100"
              :key="customerKey"
              :value="selectedCustomer ? selectedCustomer.customerNumber : ''"
              :placeholder="t('SELECT_CUSTOMER')"
              :options="JSON.stringify(customerNumberOptions)"
              icon-size="sm"
              tabIndex="0"
              @optionSelected="handleCustomerChange($event.detail)"
            />
          </div>

          <telia-skeleton
            v-if="states.loading.status === Status.Pending"
            class="skeleton__agreement-selector"
          />
          <div v-else class="inline-wrapper">
            <telia-p>
              {{ t("AGREEMENT") }}
            </telia-p>
            <b2x-inline-dropdown
              t-id="agreement-selector"
              class="scope-selector__dropdown"
              heading-element="telia-p"
              heading-variant="paragraph-100"
              :key="agreementKey"
              :value="selectedAgreement ? selectedAgreement.agreementNumber : ''"
              :placeholder="t('SELECT_AGREEMENT')"
              :options="JSON.stringify(agreementOptions)"
              icon-size="sm"
              tabIndex="0"
              @optionSelected="handleAgreementChange($event.detail)"
            />
          </div>
        </telia-col>
      </telia-row>
      <telia-row>
        <telia-col width="12">
          <div v-if="showMcOnlineButton" class="order-flow-choice">
            <telia-link
              :href="`/foretag/mybusiness/${scopeId}/bestall/${selectedOrganisation.tscid}/mobilabonnemang`"
              :disable-visited="true"
              variant="standalone"
              @click="trackMcOnlineLinkClick"
            >
              {{ t("ROUTING.ORDER_FLOW_CHOICE") }}
            </telia-link>
          </div>
        </telia-col>
      </telia-row>
      <telia-row>
        <telia-col width="12">
          <div v-if="states.loading.status !== Status.Rejected" key="product-selection">
            <product-list
              key="product-list"
              :selectedAgreement="selectedAgreement"
              :productGroups="productGroups"
              :states="states"
              @showProductConfiguration="showProductConfiguration"
            />
          </div>
        </telia-col>
      </telia-row>
    </telia-grid>
  </b2b-layout>
</template>

<script>
import {
  corpAgreements,
  corpMobileCustomerService,
  corpMobileSubscriptionTypeService,
} from "@telia/b2b-rest-client";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { getOrganizations, isTeliaAdmin } from "@telia/b2b-logged-in-service";
import { fetchOrderPageRecommendations } from "@telia/b2b-recommendations";
import { recommendationsEnabled, temporaryIssues } from "./helpers/featureToggles";
import {
  trackStartOfOrderFlow,
  trackProductClick,
  trackStartOfOrderFlowGa4,
  trackProductListingGa4,
  trackViewProductGa4,
  trackSelectProductGa4,
  trackFunnelFailGa4,
} from "./helpers/ga";
import { AgreementType } from "@telia/b2b-ecommerce-tracking";
import { generateUUID, getSelectedOrderFlow, isFunctionSegment } from "./helpers";
import { translateSetup, translateMixin } from "./locale";
import { logInfo } from "./helpers/dataLogging";
import PageAlert from "./components/ui-components/page-alert";
import ProductConfiguration from "./components/product-configuration";
import ProductList from "./components/product-list/product-list.vue";
import "@telia/b2x-drawer";
import "@telia/b2x-inline-dropdown";
import "@telia/b2x-card";

const Status = Object.freeze({
  Idle: 0,
  Pending: 1,
  Resolved: 2,
  Rejected: 3,
});
const OrderFlow = Object.freeze({
  orderMobileSubscription: "oppna-mobilt-abonnemang",
  m2m: "oppna-iot-abonnemang",
});

export default {
  name: "b2b-order-mobile-subscription",
  components: {
    ProductConfiguration,
    PageAlert,
    ProductList,
  },
  mixins: [translateMixin],
  data() {
    return {
      Status,
      OrderFlow,
      states: {
        translations: {
          status: Status.Pending,
          message: "",
          type: "",
        },
        loading: {
          status: Status.Idle,
          message: "",
          type: "",
        },
        products: {
          status: Status.Idle,
          message: "",
          type: "",
        },
      },
      scopeId: "",
      organisations: [],
      customers: [],
      agreements: [],
      nfaAgreements: [],
      customerNumberOptions: [],
      agreementOptions: [],
      agreementGroup: "",
      productGroups: [],
      selectedOrganisation: { tscid: null, name: null },
      selectedCustomer: null,
      customerKey: generateUUID(),
      selectedAgreement: null,
      agreementKey: generateUUID(),
      selectedProduct: null,
      showProductConfigurationModal: false,
      teasers: null,
      selectedOrderFlow: "",
      scopeSelectorKey: generateUUID(),
      isTeliaAdmin: false,
      isSubmitting: false,
      temporaryIssues: temporaryIssues(),
    };
  },
  async mounted() {
    trackStartOfOrderFlow();
    await translateSetup();
    this.states.translations.status = Status.Resolved;
    this.selectedOrderFlow = await getSelectedOrderFlow();
    this.isTeliaAdmin = await isTeliaAdmin();
    await this.setup();
  },
  computed: {
    isSelectedOrderFlowMobile() {
      return this.selectedOrderFlow === OrderFlow.orderMobileSubscription;
    },
    orgHasNfAgreement() {
      return this.agreementOptions?.some((agreement) =>
        this.nfaAgreements.some((nfaAgreement) => nfaAgreement.agreementNumber === agreement.value)
      );
    },
    getOrderFlowTitle() {
      return this.isSelectedOrderFlowMobile
        ? this.t("ORDER_MOBILE_SUBSCRIPTION_HEADING")
        : this.t("ORDER_IOT_SUBSCRIPTION_HEADING");
    },
    showMcOnlineButton() {
      return this.isSelectedOrderFlowMobile && this.selectedOrganisation && this.orgHasNfAgreement;
    },
  },
  methods: {
    async setup() {
      this.setState("loading", Status.Pending);

      try {
        this.scopeId = await getScopeIdOrThrow();
      } catch {
        this.setState("loading", Status.Rejected, this.t("COULD_NOT_FETCH_USER_INFORMATION"));
        trackFunnelFailGa4("Kunde inte hämta användarinformation");
        return;
      }

      try {
        const isFunctionSegmentCustomer = await isFunctionSegment(this.scopeId);
        if (isFunctionSegmentCustomer) {
          this.setState("loading", Status.Rejected, this.t("FUNCTION_SEGMENT_INFO"));
          return;
        }
      } catch (e) {
        this.setState("loading", Status.Rejected, this.t("COULD_NOT_FETCH_SEGMENT"));
        trackFunnelFailGa4("Kunde inte hämta segment");
        return;
      }

      const urlPaths = window.location.pathname.split("/");

      let organisations;
      try {
        organisations = await getOrganizations();
      } catch {
        this.setState("loading", Status.Rejected, this.t("COULD_NOT_FETCH_ORGANISATIONS"));
        trackFunnelFailGa4("Kunde inte hämta organisationer");
        return;
      }

      this.organisations = organisations;
      const organisationLocal = window.localStorage.getItem(`selectedOrganisation-${this.scopeId}`);
      const preSelectedOrganisation = urlPaths[6];

      if (preSelectedOrganisation) {
        this.selectedOrganisation = this.organisations.find(
          (organisation) => organisation.tscid === preSelectedOrganisation
        );
      } else if (organisationLocal) {
        this.selectedOrganisation = this.organisations.find(
          (organisation) => organisation.tscid === organisationLocal
        );
      } else {
        this.setState("loading", Status.Rejected, this.t("NO_SELECTED_ORGANISATION"));
        return;
      }

      this.getNfAgreements();
      this.setState("loading", Status.Resolved);
      await this.setupRecommendations();

      this.setState("products", Status.Pending);
      try {
        await this.setupCustomers();
        await this.setupAgreements();
        await this.setupProducts();
      } catch (e) {
        if (e && e.message) {
          this.setState("products", Status.Rejected, e.message);
        } else {
          this.setState("products", Status.Rejected, e, "info");
        }
        this.setState("loading", Status.Resolved);
        return;
      }
      this.setState("products", Status.Resolved);
      this.setState("loading", Status.Resolved);
    },
    setState(state, status, message = "", type = "alert") {
      this.states[state].status = status;
      this.states[state].message = message;
      this.states[state].type = type;
    },
    showProductConfiguration(product) {
      this.selectedProduct = product;
      trackProductClick(product);
      trackSelectProductGa4(product, this.agreementGroup);
      trackViewProductGa4(product, this.agreementGroup);
      this.showProductConfigurationModal = true;
    },

    //TODO: Remove if b2b-layout and RAM replication solution is working as intended
    async handleOrganisationChange(tscId) {
      if (tscId === this.selectedOrganisation?.tscid) return;

      this.setState("products", Status.Pending);
      this.selectedOrganisation = this.organisations.find(
        (organisation) => organisation.tscid === tscId
      );

      await this.setupRecommendations();

      this.resetCustomers();
      this.resetAgreements();
      this.resetProducts();
      try {
        await this.setupCustomers();
        await this.setupAgreements();
        await this.setupProducts();
      } catch (e) {
        if (e && e.message) {
          this.setState("products", Status.Rejected, e.message);
        } else {
          this.setState("products", Status.Rejected, e, "info");
        }
        return;
      }
      this.setState("products", Status.Resolved);
    },
    async handleCustomerChange(customerNumber) {
      if (customerNumber === this.selectedCustomer?.customerNumber) return;

      this.setState("products", Status.Pending);
      this.selectedCustomer = this.customers.find(
        (customer) => customer.customerNumber === customerNumber
      );

      try {
        await this.setupAgreements();
        await this.setupProducts();
      } catch (e) {
        if (e && e.message) {
          this.setState("products", Status.Rejected, e.message);
        } else {
          this.setState("products", Status.Rejected, e, "info");
        }
        return;
      }
      this.setState("products", Status.Resolved);
    },
    async handleAgreementChange(agreementNumber) {
      if (agreementNumber === this.selectedAgreement?.agreementNumber) return;

      this.setState("products", Status.Pending);
      this.selectedAgreement = this.agreements.find(
        (agreement) => agreement.agreementNumber === agreementNumber
      );

      try {
        await this.setupProducts();
      } catch (e) {
        if (e && e.message) {
          this.setState("products", Status.Rejected, e.message);
        } else {
          this.setState("products", Status.Rejected, e, "info");
        }
        return;
      }
      this.setState("products", Status.Resolved);
    },
    async setupCustomers() {
      const throwNewError = (message) => {
        this.resetCustomers();
        this.resetAgreements();
        this.resetProducts();
        throw new Error(message);
      };

      const throwMessage = (message) => {
        this.resetCustomers();
        this.resetAgreements();
        this.resetProducts();
        throw message;
      };
      let customers;
      try {
        const { results } =
          await corpMobileCustomerService.MobileCustomerControllerService.getMobileCustomer(
            this.scopeId,
            { tscId: this.selectedOrganisation.tscid }
          );
        customers = results;
      } catch {
        trackFunnelFailGa4("Kunde inte hämta kundgrupper");
        throwNewError(this.t("COULD_NOT_FETCH_CUSTOMERS"));
      }

      if (!customers.length) {
        if (!this.isTeliaAdmin) {
          logInfo(`No customer number found - ${this.selectedOrganisation?.tscid}`);
        }
        throwMessage(this.t("NO_CUSTOMERS"));
      }

      this.customerNumberOptions = customers
        .map((customer) => ({
          label: `${customer.customerNumber} - ${customer.name}`,
          value: customer.customerNumber,
        }))
        .sort((a, b) => {
          const customerA = a.label.toUpperCase();
          const customerB = b.label.toUpperCase();

          if (customerA < customerB) {
            return -1;
          } else if (customerA > customerB) {
            return 1;
          } else {
            return 0;
          }
        });

      this.customers = customers;

      const urlPaths = window.location.pathname.split("/");
      const preSelectedCustomer = urlPaths[7];

      if (this.customerNumberOptions.length === 1) {
        this.selectedCustomer = this.customers[0];
      } else if (preSelectedCustomer) {
        const preSelectedCustomerObj = this.customers.find(
          (customer) => customer.customerNumber === preSelectedCustomer
        );

        if (preSelectedCustomerObj) {
          this.selectedCustomer = preSelectedCustomerObj;
        }
      }

      this.customerKey = generateUUID();
    },
    async setupAgreements() {
      const throwNewError = (message) => {
        this.resetAgreements();
        this.resetProducts();
        throw new Error(message);
      };

      const throwMessage = (message) => {
        this.resetAgreements();
        this.resetProducts();
        throw message;
      };

      if (!this.selectedCustomer) {
        return;
      }
      let agreements;
      try {
        const { results } =
          await corpAgreements.MobileAgreementsControllerService.getMobileAgreementsNewAuth(
            this.scopeId,
            {
              tscId: this.selectedOrganisation.tscid,
              agreementLevels: ["RAM", "UNDER"],
            }
          );

        const m2mAgreements = ["DAT", "STO"];
        if (this.selectedOrderFlow === OrderFlow.m2m) {
          agreements = results
            .filter(
              (agreement) =>
                m2mAgreements.includes(agreement.agreementType) && agreement.agreementType !== "EMR"
            )
            .sort((a, b) => {
              if (a.agreementType === "DAT") {
                return -1;
              } else if (b.agreementType === "DAT") {
                return 1;
              } else {
                return 0;
              }
            });
        } else {
          agreements = results.filter(
            (agreement) => agreement.agreementType !== "DAT" && agreement.agreementType !== "EMR"
          );
        }
      } catch {
        trackFunnelFailGa4("Kunde inte hämta avtal");
        throwNewError(this.t("COULD_NOT_FETCH_AGREEMENTS"));
      }

      if (!agreements.length) {
        throwMessage(this.t("NO_AGREEMENTS"));
      }
      const mappedAgreements = agreements.map((agreement) => ({
        label: agreement.agreementNumber,
        value: agreement.agreementNumber,
      }));

      if (this.selectedOrderFlow === OrderFlow.orderMobileSubscription) {
        this.agreementOptions = mappedAgreements.sort((a, b) => {
          const agreementA = a.label.toUpperCase();
          const agreementB = b.label.toUpperCase();

          if (agreementA < agreementB) {
            return -1;
          } else if (agreementA > agreementB) {
            return 1;
          } else {
            return 0;
          }
        });
      } else {
        this.agreementOptions = mappedAgreements;
      }

      this.agreements = agreements;

      const urlPaths = window.location.pathname.split("/");
      const preSelectedAgreement = urlPaths[8];

      if (this.agreementOptions.length === 1 && this.selectedCustomer) {
        this.selectedAgreement = agreements[0];
      } else if (preSelectedAgreement) {
        const preSelectedAgreementObj = this.agreements.find(
          (agreements) => agreements.agreementNumber === preSelectedAgreement
        );

        if (preSelectedAgreementObj) {
          this.selectedAgreement = preSelectedAgreementObj;
        }
      }
      this.agreementKey = generateUUID();
    },
    async setupProducts() {
      const throwNewError = (message) => {
        this.resetProducts();
        throw new Error(message);
      };

      const throwMessage = (message) => {
        this.resetProducts();
        throw message;
      };

      if (!this.selectedAgreement) {
        return;
      }

      let subscriptions;
      let productCategories = [];

      if (this.selectedOrderFlow === OrderFlow.m2m) {
        productCategories = ["M2M"];
      } else {
        productCategories = ["VOICE", "DATA"];
      }
      try {
        const { results } =
          await corpMobileSubscriptionTypeService.MobileSubscriptionTypeControllerService.getMobileCustomer(
            this.scopeId,
            this.selectedOrganisation.tscid,
            this.selectedAgreement.agreementNumber,
            productCategories
          );
        subscriptions = results;
      } catch {
        trackFunnelFailGa4("Kunde inte hämta produkter");
        throwNewError(this.t("COULD_NOT_FETCH_PRODUCTS"));
      }

      if (!subscriptions.length) {
        throwMessage(this.t("NO_PRODUCTS"));
      }

      trackProductListingGa4(subscriptions, this.agreementGroup);

      let productGroups = [
        {
          name: this.t("MOBILE_SUBSCRIPTION"),
          showRecommendation: true,
          products: subscriptions
            .filter((subscription) => subscription.subscriptionCategory === "VOICE")
            .map((subscription) => ({
              id: subscription.subscriptionType,
              name: subscription.subscriptionName,
              type: subscription.subscriptionType,
              category: subscription.subscriptionCategory,
              index: subscriptions.findIndex(
                (s) => s.subscriptionType === subscription.subscriptionType
              ),
            })),
        },
        {
          name: this.t("MOBILE_BROADBAND"),
          showRecommendation: false,
          products: subscriptions
            .filter((subscription) => subscription.subscriptionCategory === "DATA")
            .map((subscription) => ({
              id: subscription.subscriptionType,
              name: subscription.subscriptionName,
              type: subscription.subscriptionType,
              category: subscription.subscriptionCategory,
              index: subscriptions.findIndex(
                (s) => s.subscriptionType === subscription.subscriptionType
              ),
            })),
        },
        {
          name: this.t("MACHINE_TO_MACHINE"),
          showRecommendation: false,
          products: subscriptions
            .filter((subscription) => subscription.subscriptionCategory === "M2M")
            .map((subscription) => ({
              id: subscription.subscriptionType,
              name: subscription.subscriptionName,
              type: subscription.subscriptionType,
              category: subscription.subscriptionCategory,
              index: subscriptions.findIndex(
                (s) => s.subscriptionType === subscription.subscriptionType
              ),
            })),
        },
      ].filter((productGroup) => Boolean(productGroup.products.length));

      if (!productGroups.length) {
        throwMessage(this.t("NO_PRODUCTS"));
      }

      this.productGroups = productGroups;
      const urlPaths = window.location.pathname.split("/");
      const preSelectedProduct = decodeURIComponent(urlPaths[9]);
      if (preSelectedProduct) {
        let selectedProduct = null;
        for (let i = 0; i < this.productGroups.length; i++) {
          const products = this.productGroups[i].products;
          const product = products.find((product) => product.id === preSelectedProduct);
          if (product) {
            selectedProduct = product;
            break;
          }
        }

        if (selectedProduct) {
          this.showProductConfiguration(selectedProduct);
        }
      }
    },
    async getNfAgreements() {
      try {
        const nfAgreements =
          await corpAgreements.NfAgreementsControllerService.getNfAgreementsOnScope(
            this.scopeId,
            this.selectedOrganisation.tscid
          );

        this.nfaAgreements = nfAgreements.agreements?.flat();

        if (this.nfaAgreements.some((agreement) => agreement.agreementType === "SME")) {
          this.agreementGroup = AgreementType.MFA;
        }
        if (this.nfaAgreements.every((agreement) => !agreement.agreementType)) {
          this.agreementGroup = AgreementType.RAM;
        }
        if (
          this.nfaAgreements.every(
            (agreement) => agreement.agreementType && agreement.agreementType !== "SME"
          )
        ) {
          this.agreementGroup = AgreementType.NFA;
        }
        trackStartOfOrderFlowGa4(this.agreementGroup);
      } catch {
        this.setState("loading", Status.Rejected, this.t("COULD_NOT_FETCH_AGREEMENTS"));
        trackFunnelFailGa4("Kunde inte hämta avtal");
        return;
      }
    },
    async setupRecommendations() {
      if (recommendationsEnabled()) {
        try {
          this.teasers = await fetchOrderPageRecommendations(this.selectedOrganisation.tscid);
        } catch (e) {
          this.teasers = null;
        }
      }
    },
    resetCustomers() {
      this.selectedCustomer = null;
      this.customers = [];
      this.customerNumberOptions = [];
    },
    resetAgreements() {
      this.selectedAgreement = null;
      this.agreements = [];
      this.agreementOptions = [];
    },
    resetProducts() {
      this.selectedProduct = null;
      this.productGroups = [];
    },
    hideModal() {
      if (this.isSubmitting) return;
      this.showProductConfigurationModal = false;
    },
    trackM2mLinkClick() {
      logInfo("IoT-sub entry point: Order Mobile Subscription");
    },
    trackMcOnlineLinkClick() {
      logInfo("McOnline entry point: Order Mobile Subscription");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/borders/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.page-header-container {
  background-color: $telia-gray-50;
  padding: $telia-spacing-16 0;
}

.order-mobile-subscription {
  min-height: calc(100vh - 326px);

  :deep(.sliding-modal__header) {
    align-items: center !important;
    padding-top: 4.4rem !important;
  }

  :deep(.accordion-tab-label) {
    padding: 2.4rem 0 !important;
  }

  :deep(.accordion-tab-label > telia-icon) {
    top: calc(4.9rem / 2);
  }

  :deep(.accordion-tab-content) {
    max-width: 100% !important;
    padding-right: 0 !important;
  }
}

.scope-selector {
  margin-bottom: $telia-spacing-24;
}
.page-alert-wrapper {
  max-width: 58rem;
}
.inline-wrapper {
  display: flex;
  align-items: center;

  @media screen and (max-width: $telia-breakpoint-medium) {
    flex-direction: column;
  }
}
.m2m-info-wrapper {
  max-width: 40rem;
  margin: $telia-spacing-48 0;
  &__link {
    padding: $telia-spacing-24 0;
    display: block;
  }
}

.skeleton {
  &__info-banner {
    height: 8.8rem;
    width: 117rem;
    margin-bottom: $telia-spacing-48;
  }
  &__org-selector {
    height: 4rem;
    width: 50rem;
    margin-bottom: $telia-spacing-4;
  }
  &__customer-selector {
    height: $telia-spacing-24;
    width: 40rem;
    margin-bottom: $telia-spacing-4;
  }
  &__agreement-selector {
    height: $telia-spacing-24;
    width: 30rem;
    margin-bottom: $telia-spacing-4;
  }
}

.order-flow-choice {
  margin-bottom: $telia-spacing-12;
}
.issues-notification {
  color: black;
  width: fit-content;
}
</style>
