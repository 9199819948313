<template>
  <div>
    <telia-row v-if="states.products.status === Status.Rejected" key="fetch-products-error">
      <telia-col width="12">
        <page-alert
          t-id="alert"
          class="page-alert-wrapper__products"
          :variant="states.products.type"
        >
          <telia-p>{{ states.products.message }}</telia-p>
        </page-alert>
      </telia-col>
    </telia-row>
    <section key="product-group">
      <telia-row v-if="states.products.status === Status.Pending && selectedAgreement">
        <telia-col width="12">
          <telia-row
            v-for="headerSkeleton in 2"
            :key="headerSkeleton"
            class="skeleton__products-wrapper"
          >
            <telia-col width="12">
              <telia-skeleton class="skeleton__products-header" />
            </telia-col>
            <telia-col
              v-for="productSkeleton in 8"
              :key="productSkeleton"
              width="12"
              width-md="4"
              width-lg="3"
            >
              <telia-skeleton class="skeleton__products" />
            </telia-col>
          </telia-row>
        </telia-col>
      </telia-row>
      <telia-row
        v-else
        t-id="form"
        v-for="productGroup in productGroups"
        :key="productGroup.name"
        class="products"
      >
        <telia-col width="12">
          <telia-col class="products__header" width="12">
            <img
              class="products__header__image"
              :src="simcardImage"
              :alt="productGroup.name"
              draggable="false"
            />
            <telia-heading class="products__header__text" tag="h3" variant="title-200">
              {{ productGroup.name }}
            </telia-heading>
          </telia-col>
          <telia-row>
            <telia-col
              v-for="product in productGroup.products"
              :key="product.id"
              width="12"
              width-md="4"
              width-lg="3"
              tabIndex="0"
              @keydown.enter="showProductConfiguration(product)"
              @click="showProductConfiguration(product)"
            >
              <product-card :title="product.name" :imgAlt="product.name" />
            </telia-col>
          </telia-row>
        </telia-col>
        <telia-col width="12" v-if="productGroup.showRecommendation && teasers">
          <recommendation
            class="recommendation"
            :teaser="teasers[0]"
            :expand-label="t('EXPAND_LABEL')"
            :expand-accessible-label="t('EXPAND_ACCESSIBLE_LABEL')"
            :collapse-label="t('COLLAPSE_LABEL')"
            :collapse-accessible-label="t('COLLAPSE_ACCESSIBLE_LABEL')"
          />
        </telia-col>
      </telia-row>
    </section>
  </div>
</template>

<script>
import ProductCard from "./product-card.vue";
import { translateMixin } from "../../locale";
import Recommendation from "./recommendation";
import PageAlert from "../ui-components/page-alert.vue";
import simcardImage from "../../assets/simcard.png";
const Status = Object.freeze({
  Idle: 0,
  Pending: 1,
  Resolved: 2,
  Rejected: 3,
});

export default {
  name: "product-list",
  mixins: [translateMixin],
  props: {
    productGroups: {
      type: Array,
    },
    selectedAgreement: {
      type: Object,
    },
    teasers: {
      type: Array,
    },
    states: {
      type: Object,
    },
  },
  components: {
    PageAlert,
    ProductCard,
    Recommendation,
  },
  data() {
    return {
      Status,
      simcardImage,
    };
  },
  methods: {
    showProductConfiguration(product) {
      this.$emit("showProductConfiguration", product);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/borders/variables";
@import "node_modules/@teliads/components/foundations/breakpoints/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.products {
  margin-bottom: $telia-spacing-64;
  &__header {
    display: flex;
    align-items: center;
    border-bottom: map-get($border-widths, "xs") solid $telia-gray-300;
    padding-bottom: $telia-spacing-12;

    &__image {
      max-height: 6.4rem;
      margin-right: $telia-spacing-12;
    }

    &__text {
      flex: 1;
    }
  }
}
.recommendation {
  margin-top: 8rem;
}

.page-alert-wrapper {
  &__static {
    margin-bottom: $telia-spacing-16;
  }
  &__products {
    max-width: 90rem;
    margin-top: $telia-spacing-24;
  }
}
.skeleton {
  &__products-wrapper {
    margin-bottom: $telia-spacing-64;
  }
  &__products-header {
    height: 7.6rem;
    width: 117rem;
  }
  &__products {
    width: 100%;
    height: 18rem;
    margin: 3rem $telia-spacing-12 $telia-spacing-12 0;
  }
}
</style>
