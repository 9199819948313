const OrderFlow = Object.freeze({
  orderMobileSubscription: "oppna-mobilt-abonnemang",
  m2m: "oppna-iot-abonnemang",
});

function getSelectedOrderFlow() {
  const orderFlow = getOrderFlow();
  return orderFlow;
}

const getOrderFlow = () => {
  const pathname = window.location.pathname;
  const m2m = pathname.includes(OrderFlow.m2m);
  return m2m ? OrderFlow.m2m : OrderFlow.orderMobileSubscription;
};

export { getSelectedOrderFlow };
