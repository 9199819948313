<template>
  <div class="product-card">
    <div class="card-wrapper">
      <img :src="simcardImage" :alt="imgAlt" class="product-image" />
      <div class="title-wrapper">
        <telia-p>
          <strong>{{ title }}</strong>
        </telia-p>
      </div>
    </div>
  </div>
</template>

<script>
import simcardImage from "../../assets/simcard.png";
export default {
  name: "ProductCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    imgAlt: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      simcardImage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";
.product-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  margin-top: 3rem;
  text-align: center;
}
.product-card > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.card-wrapper {
  background: $telia-gray-50;
  border: 1px solid $telia-gray-100;
  margin-bottom: $telia-spacing-24;
  padding-top: $telia-spacing-16;
}
.card-wrapper:hover {
  border: 1px solid $telia-purple-500;
  color: $telia-purple-500;
  cursor: pointer;
}
.product-image {
  width: 10rem;
  min-width: 10rem;
  margin-bottom: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}
.title-wrapper {
  flex: 1;
  padding: 0 $telia-spacing-8;
}
</style>
