import * as analytics from "@telia/b2b-web-analytics-wrapper";
import { ga4tracking, AgreementType } from "@telia/b2b-ecommerce-tracking";

// TODO: Differentiate agreement types further?
const commonGa4 = {
  siteVersion: "legacy",
  isLoggedIn: true,
  pageSegment: "mybusiness",
  funnelGroup: "mobile_telephony",
  listName: "Beställ mobilabonnemang",
};

export function trackStartOfOrderFlowGa4(agreementType) {
  ga4tracking.trackInitiateSalesflow({
    ...commonGa4,
    agreementType: agreementType,
  });
}

export function trackProductListingGa4(products, agreementType) {
  if (products && products.length > 0) {
    ga4tracking.trackProductList({
      ...commonGa4,
      agreementType: agreementType,
      products: products.map((product) => buildProductPayloadGa4(product)),
    });
  }
}

export function trackSelectProductGa4(product, agreementType) {
  ga4tracking.trackSelectItem({
    ...commonGa4,
    agreementType: agreementType,
    product: buildProductPayloadGa4(product),
    productIndex: product.index,
  });
}

export function trackViewProductGa4(product, agreementType) {
  ga4tracking.trackViewItem({
    ...commonGa4,
    agreementType: agreementType,
    product: buildProductPayloadGa4(product),
    productIndex: product.index,
  });
}

export function trackPurchaseGa4(product, agreementType) {
  ga4tracking.trackPurchase({
    ...commonGa4,
    agreementType: agreementType,
    orderId: undefined,
    totalPrice: undefined,
    products: [buildProductPayloadGa4(product)],
  });
}

export function trackFunnelFailGa4(errorMessage, agreementType) {
  ga4tracking.trackFunnelFail({
    ...commonGa4,
    agreementType: agreementType,
    errorMessage: errorMessage,
    funnelGroup: "mobilabonnemang",
  });
}

export function trackStartOfOrderFlow() {
  analytics.trackEvent(
    analytics.category.SALES_MFA_RAM,
    analytics.action.INITIATED,
    "order-mobile-subscription",
    0,
    [
      {
        type: analytics.customDimensions.AGREEMENT_TYPE,
        value: "MFA/RAM",
      },
      {
        type: analytics.customDimensions.IMPERSONATION,
        value: "End Customer",
      },
    ]
  );
}

export function trackProductClick(product) {
  const payload = buildProductPayload(product);

  analytics.trackEvent(
    analytics.category.SALES_MFA_RAM,
    analytics.action.PRODUCT_CLICK,
    product.name,
    0,
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

export function trackProductConfiguration(selectedProduct) {
  const payload = buildProductPayload(selectedProduct);

  analytics.trackEvent(
    analytics.category.SALES_MFA_RAM,
    analytics.action.SELECTED,
    selectedProduct.name,
    0,
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

export function trackConfirmOrder(selectedProduct) {
  const payload = {
    cd4: "MFA/RAM", // teliaAnalytics requires cd4 'ProductOffer', look into this
  };

  analytics.trackEvent(
    analytics.category.SALES_MFA_RAM,
    analytics.action.ORDER_COMPLETED,
    selectedProduct.name,
    0,
    Object.keys(payload).map((key) => ({
      type: key,
      value: payload[key],
    }))
  );
}

function buildProductPayload(product) {
  const productPayload = {
    id: product.id,
    name: product.name,
    category: product.category,
    brand: "Telia",
    productCode: "",
    totalUnitFee: "0.00",
  };

  return analytics.writeProductData(
    {
      cd4: "MFA/RAM", // teliaAnalytics requires cd4 'ProductOffer', look into this
    },
    { ...productPayload },
    1,
    1
  );
}

function buildProductPayloadGa4(product) {
  return {
    id: product.subscriptionType || product.type,
    name: product.subscriptionName || product.name,
    category: getProductCategory(product),
    subCategory: getProductSubCategory(product),
    brand: "Telia",
    price: undefined,
    recurringPrice: undefined,
    onetimePrice: undefined,
    discount: undefined,
    quantity: 1,
    newOrExtend: "new",
  };
}

function getProductCategory(product) {
  const category = product.subscriptionCategory || product.category;
  if (category === "VOICE") {
    return "mobilevoicesubscription";
  } else if (category === "DATA") {
    return "mobilebroadbandsubscription";
  } else if (category === "M2M") {
    return "other";
  }
  return undefined;
}

function getProductSubCategory(product) {
  const category = product.subscriptionCategory || product.category;
  if (category === "VOICE") {
    return "voice";
  } else if (category === "DATA") {
    return "broadband";
  } else if (category === "M2M") {
    return "iot";
  }
  return undefined;
}
