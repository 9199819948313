<template>
  <div class="container">
    <telia-p v-if="heading" class="heading">{{ heading }}</telia-p>
    <div class="wrapper">
      <div class="image-wrapper">
        <img
          class="image"
          :src="`https://www.telia.se/${teaser.image.url}`"
          :alt="teaser.image.altText"
        />
      </div>
      <div class="content">
        <b2x-collapser
          :expand-label="expandLabel"
          :expand-collapse-label="expandAccessibleLabel"
          :collapse-label="collapseLabel"
          :collapse-accessible-label="collapseAccessibleLabel"
          size="sm"
        >
          <telia-heading tag="h3" variant="title-200" class="title">
            {{ teaser.title }}
          </telia-heading>
          <telia-p style="white-space: pre-line">{{ teaser.richPreamble }}</telia-p>
        </b2x-collapser>
      </div>
    </div>
  </div>
</template>

<script>
import * as analytics from "@telia/b2b-web-analytics-wrapper";
import "@telia/b2x-collapser";
export default {
  name: "recommendation",
  props: {
    teaser: {
      type: Object,
      required: true,
    },
    heading: {
      type: String,
      required: false,
    },
    expandLabel: {
      type: String,
      required: true,
    },
    expandAccessibleLabel: {
      type: String,
      required: true,
    },
    collapseLabel: {
      type: String,
      required: true,
    },
    collapseAccessibleLabel: {
      type: String,
      required: true,
    },
  },
  mounted() {
    analytics.trackEventWithoutPrefix(
      analytics.category.RECOMMENDATIONS,
      analytics.action.VIEW,
      analytics.label.SHOW_RECOMMENDATION,
      null,
      [
        {
          type: analytics.customDimensions.PROMO_ID,
          value: this.teaser.id,
        },
        {
          type: analytics.customDimensions.PROMO_NAME,
          value: analytics.category.RECOMMENDATIONS,
        },
        {
          type: analytics.customDimensions.PROMO_CREATIVE,
          value: this.teaser.title,
        },
        {
          type: analytics.customDimensions.PROMO_POSITION,
          value: "1",
        },
      ]
    );
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/breakpoints/variables.scss";

.container {
  background-color: $telia-gray-100;
}

.heading {
  margin-bottom: $telia-spacing-12;
}

.wrapper {
  display: flex;
  flex-direction: column;

  @media (min-width: $telia-breakpoint-large) {
    flex-direction: row-reverse;
  }
}

.image-wrapper {
  position: relative;
  width: 100%;
  max-height: 40rem;
  overflow: hidden;

  @media (min-width: $telia-breakpoint-large) {
    width: 50%;
    max-height: none;
  }
}

.image {
  height: 40rem;
  width: 100%;
  object-fit: cover;
  @media (min-width: $telia-breakpoint-large) {
    height: 100%;
    position: absolute;
  }
}

.content {
  background: $telia-gray-100;
  padding: $telia-spacing-32;
  width: 100%;

  @media (min-width: $telia-breakpoint-large) {
    width: 50%;
  }
}

.title {
  margin-bottom: $telia-spacing-16;
}
</style>
