<template>
  <div t-id="new-number-from-series">
    <div class="select-number">
      <div v-if="isFetchingMsisdns" class="new-number__loading">
        <b2x-spinner size="small" />
      </div>
      <div v-else-if="!isNoAvailableNumbers">
        <div class="number-series-input-container">
          <div class="input-wrapper">
            <telia-text-input
              t-id="new-number-input"
              inputmode="tel"
              :label="t('SEARCH_FOR_NUMBER_IN_NUMBER_SERIES')"
              :error-message="getValidationMessage()"
              @keyup.enter="searchBeginNumber()"
              @input="numberSeriesSearchString = $event.target.value"
              style="max-width: 32.2rem"
            />
          </div>
          <div class="button-wrapper">
            <telia-button size="sm" variant="secondary" type="button" @click="searchBeginNumber()">
              {{ t("SEARCH") }}
            </telia-button>
          </div>
        </div>
        <div t-id="new-number-searchResultsContainer" class="chip-choice-container">
          <telia-chip-choice
            v-for="(msisdn, index) in formattedReservedNumbers"
            :key="msisdn.value"
            :t-id="'msisdn-option-' + index"
            class="chip-choice-item"
            :text="msisdn.listDisplayName"
            :value="msisdn.value"
            :disabled="isBookingNumber"
            :checked="selectedNumber === msisdn.value"
            @click="selectedNumber = msisdn.value"
          />
        </div>
      </div>
    </div>
    <page-alert
      v-if="numbersAndBookingIds.length === 0 && !isFetchingMsisdns"
      t-id="number-series-alert"
      class="no-available-numbers-alert"
    >
      {{ errorMessage }}
    </page-alert>
    <telia-p v-if="selectedNumber">
      {{ t("PHONE_NUMBER_CHOSEN_NUMBER") }}
      <strong>
        {{ formatMsisdn(selectedNumber) }}
      </strong>
    </telia-p>
  </div>
</template>

<script>
import { msisdnMixin } from "../mixins";
import { translateMixin } from "../../locale";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, helpers } from "@vuelidate/validators";
import { corpNumberBooking } from "@telia/b2b-rest-client";
import { logInfo } from "../../helpers";
import PageAlert from "../ui-components/page-alert.vue";
import "@telia/b2x-spinner";

const beginsWithValidator = (value) =>
  !helpers.req(value) || (value.startsWith("07") && value.length > 1) || value === "0";
// eslint-disable-next-line
const numbersAndWildcardValidator = (value) => value.match(/^[\d%*]*$/i);

export default {
  name: "new-number-from-series",
  mixins: [msisdnMixin, translateMixin],
  components: {
    PageAlert,
  },
  props: {
    scopeId: { type: String, required: true },
    tscid: { type: String, required: true },
    selectedCustomer: { type: Object, required: true },
    extendBookingTimeoutMinutes: { type: Number, default: 10 },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: () => ({
    numberCountToFetch: 3,
    reservedNumbers: [],
    numbersAndBookingIds: [],
    selectedNumber: null,
    selectedBookingId: "",
    numberSeriesSearchString: "",
    isBookingNumber: false,
    isFetchingMsisdns: false,
    isNoAvailableNumbers: false,
    isFetchError: false,
    errorMessage: "",
    extendTimer: "",
  }),
  created() {
    window.addEventListener("unmounted", this.releaseAllBookedNumbers);
  },
  async mounted() {
    this.fetchNewReservedNumbers();
  },
  async beforeUnmount() {
    window.removeEventListener("unmounted", this.releaseAllBookedNumbers);
    this.releaseAllBookedNumbers();
  },
  computed: {
    formattedReservedNumbers() {
      return this.numbersAndBookingIds.map((number) => ({
        value: number.msisdn,
        listDisplayName: this.formatMsisdn(number.msisdn),
        bookingId: number.bookingId,
      }));
    },
    valid() {
      return !this.v$.numberSeriesSearchString.$invalid;
    },
  },
  methods: {
    releaseAllBookedNumbers() {
      if (this.numbersAndBookingIds.length) {
        this.numbersAndBookingIds.forEach((el) => {
          corpNumberBooking.PublicNumberBookingControllerService.releaseBooking(
            this.scopeId,
            this.tscid,
            el.bookingId
          );
        });
        this.removeExtendBookingTimer();
      }
    },
    async searchBeginNumber() {
      if (this.valid) {
        await this.fetchNewReservedNumbers();
        logInfo(
          "Number series search and total amount of result: " + this.numbersAndBookingIds.length
        );
      }
    },
    async bookAllReservedNumbers() {
      const customerNumber = this.selectedCustomer.customerNumber;
      this.numbersAndBookingIds = [];
      for (const reservedNumber of this.reservedNumbers) {
        const bookedNumber =
          await corpNumberBooking.PublicNumberBookingControllerService.bookReservedNumber(
            this.scopeId,
            this.tscid,
            reservedNumber.msisdn,
            "",
            customerNumber
          );
        this.numbersAndBookingIds.push({
          bookingId: bookedNumber.bookingId,
          msisdn: reservedNumber.msisdn,
        });
      }

      this.selectMsisdn();
      this.extendBookingOnTimeout();
    },

    selectMsisdn() {
      if (!this.numbersAndBookingIds.length) return;
      this.selectedNumber = this.numbersAndBookingIds[0].msisdn;
      this.selectedBookingId = this.numbersAndBookingIds[0].bookingId;
      this.emitBookingId();
    },
    extendBookingOnTimeout() {
      const timeout = this.extendBookingTimeoutMinutes * 60 * 1000; // minutes to ms
      this.extendTimer = setTimeout(this.extendBooking, timeout);
    },
    removeExtendBookingTimer() {
      clearTimeout(this.extendTimer);
      this.extendTimer = null;
    },
    async extendBooking() {
      try {
        this.numbersAndBookingIds.forEach((el) => {
          corpNumberBooking.PublicNumberBookingControllerService.extendBooking(
            this.scopeId,
            this.tscid,
            el.bookingId
          );
        });
      } catch (e) {
        return;
      }
      this.extendBookingOnTimeout();
    },
    async fetchNewReservedNumbers() {
      this.releaseAllBookedNumbers();
      this.selectedNumber = null;
      this.isFetchError = false;
      this.isFetchingMsisdns = true;
      const msisdn = await this.formatToMsisdn(this.numberSeriesSearchString);

      try {
        const reservedNumbers =
          await corpNumberBooking.PublicNumberBookingControllerService.getReservedNumbersForTscid(
            this.scopeId,
            this.tscid,
            this.selectedCustomer.customerNumber,
            this.numberCountToFetch,
            msisdn ? msisdn : null
          );
        this.reservedNumbers = reservedNumbers.filter((number) => number.reservedStatus === "FREE");
        await this.bookAllReservedNumbers();
        this.selectMsisdn();
      } catch {
        this.errorMessage = this.t("NUMBER_SERIES_ERROR");
        this.isFetchError = true;
      } finally {
        this.isFetchingMsisdns = false;
        if (this.reservedNumbers.length === 0 && !this.isFetchError) {
          this.errorMessage = msisdn
            ? this.t("NO_NUMBERS_IN_SERIE_FOUND_INFORMATION")
            : this.t("NO_NUMBERS_AVAILABLE");
        }
      }
    },
    getValidationMessage() {
      if (!this.v$.numberSeriesSearchString.maxLength) {
        return this.t("PHONE_NUMBER_SEARCH_VALIDATION_LENGTH");
      }
      if (!this.v$.numberSeriesSearchString.beginsWithValidator) {
        return this.t("PHONE_NUMBER_SEARCH_VALIDATION_BEGINS_WITH_07");
      }
      if (!this.v$.numberSeriesSearchString.numbersAndWildcardValidator) {
        return this.t("PHONE_NUMBER_SEARCH_VALIDATION_INVALID_CHARACTER");
      }
    },
    emitBookingId() {
      this.$emit("new-booking-id", this.selectedBookingId);
    },
  },
  watch: {
    numberSeriesSearchString: {
      handler(value) {
        if (!value && this.reservedNumbers.length === 0) {
          this.searchBeginNumber();
        }
      },
    },
    selectedNumber: {
      handler(value) {
        if (!value) {
          this.selectedMsisdn = null;
          this.selectedBookingId = null;
        } else {
          this.selectedMsisdn = value;
          const number = this.numbersAndBookingIds.find((el) => el.msisdn === value);
          this.selectedBookingId = number.bookingId;
        }
        this.$emit("selectedMsisdn", this.selectedMsisdn);
        this.emitBookingId();
      },
    },
  },
  validations: {
    numberSeriesSearchString: {
      maxLength: maxLength(10),
      beginsWithValidator,
      numbersAndWildcardValidator,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.no-available-numbers-alert {
  max-width: fit-content;
  margin-bottom: $telia-spacing-12;
}
.number-series-input-container {
  margin-bottom: $telia-spacing-8;
  display: flex;
}
.chip-choice-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $telia-spacing-12;
}
.chip-choice-item {
  margin-right: $telia-spacing-8;
  margin-bottom: $telia-spacing-4;
}
.input-wrapper {
  margin-bottom: $telia-spacing-12;
  flex: 1;
  max-width: 32rem;
}
.button-wrapper {
  text-align: right;
  margin-top: 2.6rem;
  margin-left: $telia-spacing-20;
}
</style>
