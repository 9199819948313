<template>
  <div class="chip-choice-container">
    <telia-chip-choice
      class="chip-choice-item"
      :text="t('SECRET_NUMBER')"
      value="SECRET_NUMBER"
      :checked="isSelected('SECRET_NUMBER')"
      @click="toggleService('SECRET_NUMBER')"
    />
  </div>
</template>

<script>
import { translateMixin } from "../locale";

export default {
  name: "services",
  mixins: [translateMixin],
  data() {
    return {
      services: [],
    };
  },
  methods: {
    isSelected(service) {
      return this.services.indexOf(service) !== -1;
    },
    toggleService(service) {
      const index = this.services.indexOf(service);

      if (index !== -1) {
        this.services.splice(index, 1);
      } else {
        this.services.push(service);
      }
      this.$emit("setServices", this.services);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.chip-choice-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $telia-spacing-12;
}
.chip-choice-item {
  margin-right: $telia-spacing-8;
  margin-bottom: $telia-spacing-4;
}
</style>
