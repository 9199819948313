import { isEnabled as featureToggleIsEnabled } from "@telia/b2b-feature-toggles";

enum Feature {
  SHOW_RECOMMENDATIONS = "show-recommendations",
  TEMPORARY_ISSUES_WITH_ORDERING = "temporary-issues-with-ordering",
}

const toggles: Record<Feature, boolean> = {
  [Feature.SHOW_RECOMMENDATIONS]: false,
  [Feature.TEMPORARY_ISSUES_WITH_ORDERING]: false,
};

export const recommendationsEnabled = (): boolean => isEnabled(Feature.SHOW_RECOMMENDATIONS);
export const temporaryIssues = (): boolean => isEnabled(Feature.TEMPORARY_ISSUES_WITH_ORDERING);

function isEnabled(toggleName: Feature): boolean {
  return featureToggleIsEnabled(toggleName, toggles);
}
