<template>
  <div>
    <div t-id="selectBillingAccount">
      <div v-if="billingAccounts.length > 8" class="selectBillingAccount-inputContainer">
        <b2x-input-suggestions
          ref="select-billing-account-input"
          id="select-billing-account-input"
          t-id="select-billing-account-input"
          :label="t('SEARCH_INVOICE_ACCOUNT')"
          :suggestions="JSON.stringify(suggestions)"
          :value="inputText"
          @inputValue="handleInput($event.detail)"
          @suggestionSelected="selectAccount($event.detail)"
          @focus="setSuggestions"
          @blur="clearSuggestions"
        />
      </div>

      <div
        v-if="billingAccounts.length < 9"
        t-id="new-number-searchResultsContainer"
        class="chip-choice-container"
      >
        <telia-chip-choice
          v-for="(account, index) in billingAccounts"
          :key="account.number"
          :t-id="'billing-account-option-' + index"
          class="chip-choice-item"
          :value="account.number"
          :checked="selectedAccount.number === account.number"
          @click="setSelectedAccountWithNumber(account.number)"
        >
          <div>
            <div>{{ formatDisplayName(account) }}</div>
            <telia-p variant="additional-100">
              {{ account.subscriptionCount }} {{ t("SUBSCRIPTIONS") }}
            </telia-p>
          </div>
        </telia-chip-choice>
      </div>
    </div>
    <div class="selected-account-info">
      <telia-p>
        {{ t("SELECTED_INVOICE_ACCOUNT") }}
        <strong v-if="selectedAccount">
          {{ selectedAccount.accountNumber }}
        </strong>
      </telia-p>
    </div>
    <div class="alert-wrapper">
      <page-alert v-if="fetchError">
        <telia-p>{{ t("COULD_NOT_FETCH_INVOICE_INFORMATION") }}</telia-p>
      </page-alert>
    </div>
    <div class="alert-wrapper">
      <page-alert v-if="invoiceHasPrepaidSubscription">
        <telia-p>{{ t("BILLING_ACCOUNT_HAS_PREPAID_SUBSCRIPTION") }}</telia-p>
      </page-alert>
    </div>
  </div>
</template>

<script>
import { translateMixin } from "../../locale";
import PageAlert from "../ui-components/page-alert.vue";
import "@telia/b2x-input-suggestions";

export default {
  name: "select-billing-account",
  mixins: [translateMixin],
  components: {
    PageAlert,
  },
  props: {
    billingAccounts: {
      type: Array,
      default: () => [],
    },
    scopeId: {
      type: String,
      required: true,
    },
    tscid: {
      type: String,
      required: true,
    },
    invoiceHasPrepaidSubscription: {
      type: Boolean,
    },
  },
  data() {
    return {
      inputText: "",
      suggestions: [],
      selectedAccount: undefined,
      fetchError: false,
    };
  },
  async created() {
    this.selectedAccount = { ...this.billingAccounts[0] };
    await this.setSelectedAccountWithNumber(this.selectedAccount.number);
  },
  methods: {
    handleInput(input) {
      this.inputText = input;

      if (!this.inputText) {
        this.clearSelection();
      }

      this.setSuggestions();
    },
    setSuggestions() {
      if (this.inputText) {
        this.suggestions = this.billingAccounts
          .filter((account) =>
            account.displayName.toLowerCase().includes(this.inputText.toLowerCase())
          )
          .map((account) => account.displayName);
      } else {
        this.suggestions = this.billingAccounts
          .map((account) => account.displayName)
          .sort((a, b) => {
            const accountA = this.billingAccounts.find((acc) => acc.displayName === a);
            const accountB = this.billingAccounts.find((acc) => acc.displayName === b);
            return accountB.subscriptionCount - accountA.subscriptionCount;
          });
      }
    },
    clearSuggestions() {
      this.suggestions = [];
    },
    clearSelection() {
      this.selectedAccount = undefined;
      this.$emit("billing-account-selected", undefined);
    },
    async selectAccount(accountName) {
      this.fetchError = false;
      const account = {
        ...this.billingAccounts.find(
          (el) => el.displayName.toLowerCase() === accountName.toLowerCase()
        ),
      };
      this.inputText = account.displayName;
      this.selectedAccount = account;

      this.$emit("billing-account-selected", account);
    },
    async setSelectedAccountWithNumber(accountNumber) {
      this.selectedAccount = {
        ...this.billingAccounts.find((account) => account.number === accountNumber),
      };

      this.$emit("billing-account-selected", this.selectedAccount);
    },
    formatDisplayName({ accountNumber, accountReference }) {
      return `${accountNumber}${accountReference ? " / " + accountReference : ""}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/colors/variables";
@import "~@teliads/components/foundations/spacing/variables";

.selectBillingAccount-inputContainer {
  position: relative;
}

.chip-choice-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $telia-spacing-12;
}

.chip-choice-item {
  margin-right: $telia-spacing-8;
  margin-bottom: $telia-spacing-8;
}

.selected-account-info {
  margin-top: $telia-spacing-8;
}

.alert-wrapper {
  margin-top: $telia-spacing-16;
}

.clear-search-icon {
  position: absolute;
  right: $telia-spacing-12;
  top: 55%;
  color: $telia-gray-500;
  cursor: pointer;
}

.clear-search-icon:hover {
  color: $telia-purple-500;
}
</style>
