<template>
  <div class="page-alert__wrapper" :class="`page-alert__wrapper--${this.variant}`">
    <div class="page-alert__icon">
      <telia-icon :name="icon" />
    </div>
    <div class="page-alert__content">
      <telia-p>
        <slot></slot>
      </telia-p>
    </div>
  </div>
</template>

<script>
export default {
  name: "page-alert",
  props: {
    variant: {
      type: String,
      default: "alert",
    },
  },
  computed: {
    icon() {
      if (this.variant === "alert") return "alert-filled";
      else if (this.variant === "info") return "info-filled";
      else if (this.variant === "success") return "checkmark";
      else return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/variables.scss";
@import "@teliads/components/foundations/spacing/variables.scss";

.page-alert {
  &__wrapper {
    display: flex;
    border-width: 0.2rem;
    border-style: solid;
    border-radius: 0.2rem;

    &--alert {
      border-color: $telia-orange-500;
      background-color: $telia-orange-500;
    }

    &--info {
      border-color: $telia-blue-500;
      background-color: $telia-blue-500;
    }

    &--success {
      border-color: $telia-green-500;
      background-color: $telia-green-500;
    }
  }

  &__icon {
    height: 5.6rem;
    padding: $telia-spacing-16;
    color: $telia-white;
  }

  &__content {
    flex: 1;
    padding: $telia-spacing-16;
    background-color: $telia-white;
  }
}
</style>
