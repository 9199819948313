<template>
  <div class="new-billing-account">
    <div class="input-wrapper">
      <telia-text-input
        @input="newAccount.accountReference = $event.target.value"
        :value="newAccount.accountReference"
        :label="t('INVOICE_ACCOUNT_NAME')"
        maxlength="40"
        :maxlength-error-message="t('MAX_LENGTH')"
      />
    </div>
    <div v-if="!manualAddressInput" class="address-search">
      <b2x-input-suggestions
        ref="addressSuggestions"
        :label="`*${t('ADDRESS')}`"
        :suggestions="JSON.stringify(parsedAddressSuggestions)"
        :loading="isFetching"
        :value="selectedAddress"
      />
      <telia-p>{{ t("ADDRESS_PLACEHOLDER") }}</telia-p>
      <telia-button
        type="button"
        variant="text"
        class="address-input-toggle"
        @click="toggleManualAddressInput"
      >
        {{ t("MANUAL_ADDRESS_INPUT") }}
      </telia-button>
    </div>

    <div v-else-if="manualAddressInput" class="input-wrapper">
      <telia-text-input
        @input="newAccount.address = $event.target.value"
        :value="newAccount.address"
        :label="t('STREET_ADDRESS')"
        :required="manualAddressInput && !!isNewInvoiceGroup"
        :required-error-message="t('FIELD_REQUIRED')"
        maxlength="33"
        :error-message="newAccount.address.length > 33 ? t('STREET_ADDRESS_MAX_LENGTH') : ''"
      />
      <telia-text-input
        @input="newAccount.postalCode = $event.target.value"
        :value="newAccount.postalCode"
        :label="t('POSTAL_NUMBER')"
        :required="manualAddressInput && !!isNewInvoiceGroup"
        :required-error-message="t('FIELD_REQUIRED')"
        pattern="^\d+$"
        :pattern-error-message="t('PATTERN_ERROR')"
        minlength="5"
        :minlength-error-message="t('POSTAL_NUMBER_MIN_LENGTH')"
        maxlength="5"
        :maxlength-error-message="t('POSTAL_NUMBER_MAX_LENGTH')"
      />
      <telia-text-input
        @input="newAccount.city = $event.target.value"
        :value="newAccount.city"
        :label="t('CITY')"
        :required="manualAddressInput && !!isNewInvoiceGroup"
        :required-error-message="t('FIELD_REQUIRED')"
        maxlength="25"
        :maxlength-error-message="t('CITY_MAX_LENGTH')"
      />
      <telia-button
        type="button"
        variant="text"
        class="address-input-toggle"
        @click="toggleManualAddressInput"
      >
        {{ t("SEARCH_ADDRESS_INPUT") }}
      </telia-button>
    </div>
  </div>
</template>

<script>
import { corpAddressLookup } from "@telia/b2b-rest-client";
import { translateMixin } from "../../locale";
import "@telia/b2x-input-suggestions";
import "@telia/b2x-spinner";

const MINIMUM_INPUT_LENGTH_FOR_SEARCH = 4;
const SEARCH_DELAY_IN_MS = 500;

export default {
  name: "new-billing-account",
  mixins: [translateMixin],
  props: {
    scopeId: {
      type: String,
      required: true,
    },
    selectedCustomer: {
      type: Object,
      required: true,
    },
    isNewInvoiceGroup: {
      Type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isFetching: false,
      manualAddressInput: true,
      suggestions: [],
      selectedAddress: null,
      address: {},
      newAccount: {
        address: "",
        postalCode: "",
        city: "",
        accountReference: "",
      },
    };
  },
  mounted() {
    this.populateAddress();
  },
  computed: {
    parsedAddressSuggestions() {
      return this.suggestions.map(function (address) {
        return address["fullAddress"];
      });
    },
  },
  methods: {
    setupAddressEventListeners() {
      this.$refs.addressSuggestions.addEventListener("inputValue", (event) => {
        this.handleInput(event.detail);
      });
      this.$refs.addressSuggestions.addEventListener("suggestionSelected", (event) => {
        this.handleSuggestionSelect(event.detail);
      });
    },
    populateAddress() {
      this.newAccount.address = this.selectedCustomer.address || this.selectedCustomer.name;
      this.newAccount.postalCode = this.selectedCustomer.zipCode || "";
      this.newAccount.city = this.selectedCustomer.city || "";
    },
    toggleManualAddressInput() {
      this.clearAddressInput();
      this.manualAddressInput = !this.manualAddressInput;
    },
    async handleInput(input) {
      if (!input) {
        this.clearInput();
      }
      if (input.length < MINIMUM_INPUT_LENGTH_FOR_SEARCH) {
        clearTimeout(this.debounce);
        return;
      } else if (this.debounce) {
        clearTimeout(this.debounce);
      }
      this.debounce = setTimeout(async () => {
        if (!this.suggestionsIncludesInput(input, this.suggestions)) {
          this.isFetching = true;
          this.suggestions = await this.getAddressSuggestions(input);
          this.suggestions.forEach((address) => {
            const parsedStreetName = address.streetName
              .toLowerCase()
              .replace(/(^|[\s-])\S/g, (c) => c.toUpperCase());
            address.fullAddress =
              parsedStreetName +
              (address.streetNumber ? " " + address.streetNumber : "") +
              "" +
              (address.entrance ? address.entrance : "") +
              ", " +
              address.city;
          });
          this.isFetching = false;
        }
      }, SEARCH_DELAY_IN_MS);
    },
    suggestionsIncludesInput(input, suggestions) {
      if (input && suggestions) {
        return Object.keys(suggestions)
          .map((key) => key.toLowerCase().substring(0, input.length))
          .includes(input.toLowerCase());
      }
    },
    async getAddressSuggestions(input) {
      try {
        return await corpAddressLookup.AddressLookupControllerService.searchAddress1(
          this.scopeId,
          input,
          true
        );
      } catch (e) {
        console.error(e);
        this.isFetching = false;
      }
    },
    handleSuggestionSelect(address) {
      this.address = this.suggestions.find((el) => el.fullAddress === address);
      this.selectedAddress = this.address.fullAddress;
      this.newAccount.address = this.address.streetName + " " + this.address.streetNumber;
      this.newAccount.postalCode = this.address.postalCode;
      this.newAccount.city = this.address.city;

      this.$emit("setNewAccount", { ...this.newAccount });
      this.suggestions = [];
    },
    clearInput() {
      this.address = {};
      this.suggestions = [];
      this.selectedAddress = null;
      this.newAccount = {
        address: "",
        postalCode: "",
        city: "",
        accountReference: "",
      };
      this.$emit("setNewAccount", { ...this.newAccount });
    },
    clearAddressInput() {
      this.address = {};
      this.suggestions = [];
      this.selectedAddress = null;
      this.newAccount.address = "";
      this.newAccount.postalCode = "";
      this.newAccount.city = "";

      this.$emit("setNewAccount", { ...this.newAccount });
    },
  },
  watch: {
    manualAddressInput: {
      handler(val) {
        if (val) {
          this.populateAddress();
        } else {
          this.$nextTick(() => {
            this.setupAddressEventListeners();
          });
        }
      },
    },
    selectedCustomer: {
      handler() {
        this.populateAddress();
      },
    },
    newAccount: {
      deep: true,
      handler() {
        this.$emit("setNewAccount", this.newAccount);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.telia-text-input) {
  margin: 1.5rem 0;
}
.new-billing-account {
  padding-bottom: 2rem;
}
.input-wrapper,
.address-search {
  max-width: 42rem;
}
.address-input-toggle {
  :deep(.telia-button--text) {
    margin-top: 1.2rem;
    padding-left: 0;
  }
}
</style>
