<template>
  <div t-id="phone-number" class="phone-number">
    <collapser
      id="new-phone-number"
      input-group="phone-number"
      :title="t('PHONE_NUMBER_NEW_TITLE')"
      :selected="selected === 'new-phone-number'"
      :aria-expanded="selected === 'new-phone-number'"
      :aria-label="t('ALLY.LABEL.NEW_PHONE_NUMBER')"
      @expandCollapser="selected = $event"
    >
      <NewNumber
        :scope-id="scopeId"
        :tscid="tscid"
        :type="type"
        @new-booking-id="setNewNumberBookingId"
        @selectedMsisdn="msisdn['new-phone-number'] = $event"
      />
    </collapser>
    <collapser
      id="new-phone-number-from-series"
      t-id="new-phone-number-from-series"
      input-group="phone-number"
      :title="t('PHONE_NUMBER_FROM_SERIES_TITLE')"
      :selected="selected === 'new-phone-number-from-series'"
      :aria-expanded="selected === 'new-phone-number-from-series'"
      :aria-label="t('ALLY.LABEL.PHONE_NUMBER_FROM_SERIES')"
      @expandCollapser="selected = $event"
    >
      <NewNumberFromSeries
        :scope-id="scopeId"
        :tscid="tscid"
        :selected-customer="selectedCustomer"
        @new-booking-id="setNumberSeriesBookingId"
        @selectedMsisdn="msisdn['new-phone-number-from-series'] = $event"
      />
    </collapser>
  </div>
</template>

<script>
import NewNumber from "./new-number.vue";
import NewNumberFromSeries from "./new-number-from-series.vue";
import Collapser from "../ui-components/collapser.vue";
import { msisdnMixin } from "../mixins";
import { translateMixin } from "../../locale";

export default {
  name: "phone-number",
  mixins: [msisdnMixin, translateMixin],
  components: {
    Collapser,
    NewNumber,
    NewNumberFromSeries,
  },
  props: {
    scopeId: { type: String, required: true },
    tscid: { type: String, required: true },
    selectedCustomer: { type: Object, required: true },
    type: { type: String, required: true },
  },
  mounted() {
    this.emitPhoneNumber();
  },
  data: () => ({
    msisdn: {
      "keep-phone-number": null,
      "new-phone-number": null,
      "new-phone-number-from-series": null,
    },
    selected: "new-phone-number",
    bookingId: {
      "new-phone-number": null,
      "new-phone-number-from-series": null,
    },
  }),
  methods: {
    emitPhoneNumber() {
      const phoneNumberConfig = {
        phoneNumber: this.currentValue,
        isNumberSeries: this.selected === "new-phone-number-from-series",
      };
      this.$emit("setPhoneNumber", phoneNumberConfig);
    },
    emitValidity(valid) {
      this.$emit("valid", valid);
    },
    setNewNumberBookingId(bookingId) {
      this.bookingId["new-phone-number"] = bookingId;
    },
    setNumberSeriesBookingId(bookingId) {
      this.bookingId["new-phone-number-from-series"] = bookingId;
    },
    timeout() {
      this.$emit("timeout");
    },
  },
  computed: {
    currentValue() {
      return {
        component: this.selected,
        msisdn: this.msisdn[this.selected],
        formattedMsisdn: this.formatMsisdn(this.msisdn[this.selected]),
        bookingId: this.bookingId,
      };
    },
    valid() {
      return !!this.currentValue.msisdn;
    },
  },
  watch: {
    msisdn: {
      deep: true,
      handler: "emitPhoneNumber",
    },
    selected: {
      deep: true,
      handler: "emitPhoneNumber",
    },
    valid: "emitValidity",
  },
};
</script>

<style lang="scss">
.phone-number .checkout-choice {
  padding-left: 1.8rem !important;
  padding-right: 1.8rem !important;
}
</style>
