<template>
  <div t-id="new-number" class="new-number">
    <div v-if="fetchingMsisdns" class="new-number__loading">
      <b2x-spinner size="small" />
    </div>

    <div v-else>
      <div class="number-input-container">
        <div class="input-wrapper">
          <telia-text-input
            t-id="new-number-input"
            inputmode="tel"
            :label="t('PHONE_NUMBER_SEARCH')"
            :value="searchNumber"
            @keyup.enter="searchBeginNumber()"
            @input="searchNumber = $event.target.value"
            :disabled="type !== 'VOICE'"
            :error-message="getValidationMessage()"
            style="max-width: 32.2rem"
          />
        </div>
        <page-alert
          v-if="!hasSearchResults && !errorText"
          style="margin-top: 2.4rem"
          variant="info"
        >
          {{ t("NO_NUMBERS_FOUND_INFORMATION") }}
        </page-alert>
        <page-alert v-if="errorText" style="margin-top: 2.4rem">
          {{ t(errorText) }}
        </page-alert>
      </div>
      <div data-dont-collect>
        <div t-id="new-number-searchResultsContainer" class="chip-choice-container">
          <telia-chip-choice
            v-for="(msisdn, index) in msisdns"
            :key="msisdn"
            :t-id="'msisdn-option-' + index"
            class="chip-choice-item"
            :text="formatMsisdn(msisdn)"
            :value="msisdn"
            :checked="selectedMsisdn === msisdn"
            @click="setSelectedMsisdn(msisdn)"
          />
        </div>
      </div>
      <telia-p v-if="selectedMsisdn && hasSearchResults">
        {{ t("PHONE_NUMBER_CHOSEN_NUMBER") }}
        <strong>
          {{ formatMsisdn(selectedMsisdn) }}
        </strong>
      </telia-p>
    </div>
  </div>
</template>

<script>
import { corpNumberBooking } from "@telia/b2b-rest-client";
import { useVuelidate } from "@vuelidate/core";
import { maxLength, helpers } from "@vuelidate/validators";
import { msisdnMixin } from "../mixins";
import { translateMixin } from "../../locale";
import { logInfo } from "../../helpers";
import PageAlert from "../ui-components/page-alert.vue";
import "@telia/b2x-spinner";

// eslint-disable-next-line
const numbersAndWildcardValidator = (value) => value.match(/^[\d%*]*$/i);

export default {
  name: "new-number",
  mixins: [msisdnMixin, translateMixin],
  components: {
    PageAlert,
  },
  props: {
    scopeId: { type: String, required: true },
    tscid: { type: String, required: true },
    type: { type: String, required: true },
    extendBookingTimeoutMinutes: { type: Number, default: 10 },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: () => ({
    bookingId: null,
    msisdns: [],
    searchNumber: null,
    fetchingMsisdns: false,
    extendTimer: null,
    errorText: null,
    selectedMsisdn: null,
  }),
  computed: {
    searchNumberFormattedAsMsisdn() {
      return this.formatToMsisdn(this.searchNumber);
    },
    hasSearchResults() {
      return this.msisdns.length !== 0;
    },
    valid() {
      return !this.v$.searchNumber.$invalid;
    },
  },
  validations: {
    searchNumber: {
      maxLength: maxLength(10),
      numbersAndWildcardValidator,
    },
  },
  created() {
    window.addEventListener("unmounted", this.onUnload);
    this.fetchMsisdns();
  },
  async beforeUnmount() {
    window.removeEventListener("unmounted", this.onUnload);
    this.removeExtendBookingTimer(this.extendTimer);
    await corpNumberBooking.PublicNumberBookingControllerService.releaseBooking(
      this.scopeId,
      this.tscid,
      this.bookingId
    );
  },
  methods: {
    onUnload() {
      if (this.bookingId) {
        corpNumberBooking.PublicNumberBookingControllerService.releaseBooking(
          this.scopeId,
          this.tscid,
          this.bookingId
        );
        this.removeExtendBookingTimer();
      }
    },
    setSelectedMsisdn(msisdn) {
      this.selectedMsisdn = msisdn;
      logInfo("phone number was changed");
    },

    getValidationMessage() {
      if (!this.v$.searchNumber.maxLength) {
        return this.t("PHONE_NUMBER_SEARCH_VALIDATION_LENGTH");
      }
      if (!this.v$.searchNumber.numbersAndWildcardValidator) {
        return this.t("PHONE_NUMBER_SEARCH_VALIDATION_INVALID_CHARACTER");
      }
    },
    fetchMsisdns() {
      this.fetchingMsisdns = true;
      this.errorText = null;

      if (this.bookingId) {
        const searchMsisdn = this.searchNumberFormattedAsMsisdn;
        return corpNumberBooking.PublicNumberBookingControllerService.releaseAndBook(
          this.scopeId,
          this.tscid,
          this.bookingId,
          searchMsisdn ? searchMsisdn : null
        )
          .then((res) => {
            this.msisdns = res.msisdns;
            this.bookingId = res.bookingId;
          })
          .catch(() => {
            this.errorText = "NUMBER_FETCH_ERROR";
            this.msisdns = [];
          })
          .finally(() => {
            this.fetchingMsisdns = false;
          });
      } else {
        this.msisdns = [];
        const searchMsisdn = this.searchNumberFormattedAsMsisdn;
        return corpNumberBooking.PublicNumberBookingControllerService.bookNumber1(
          this.scopeId,
          this.tscid,
          this.type,
          searchMsisdn ? searchMsisdn : null
        )
          .then((res) => {
            this.msisdns = res.msisdns;
            this.bookingId = res.bookingId;
            this.extendBookingOnTimeout();
          })
          .catch(() => {
            this.errorText = "NUMBER_FETCH_ERROR";
          })
          .finally(() => {
            this.fetchingMsisdns = false;
          });
      }
    },
    selectMsisdn() {
      this.selectedMsisdn = this.msisdns[0];
      this.emitBookingId();
    },
    searchBeginNumber() {
      if (this.valid) {
        this.fetchMsisdns();
      }
    },
    emitBookingId() {
      this.$emit("new-booking-id", this.bookingId);
    },
    extendBookingOnTimeout() {
      const timeout = this.extendBookingTimeoutMinutes * 60 * 1000; // minutes to ms
      this.extendTimer = setTimeout(this.extendBooking, timeout);
    },
    removeExtendBookingTimer() {
      clearTimeout(this.extendTimer);
      this.extendTimer = null;
    },
    async extendBooking() {
      try {
        await corpNumberBooking.PublicNumberBookingControllerService.extendBooking(
          this.scopeId,
          this.tscid,
          this.bookingId
        );
      } catch (e) {
        // If we cannot extend the booking we need to start over with a new booking.
        this.bookingId = null;
        this.removeExtendBookingTimer();
        this.fetchMsisdns();
        return;
      }

      this.extendBookingOnTimeout();
    },
  },
  watch: {
    msisdns: {
      deep: true,
      handler: "selectMsisdn",
    },
    searchNumber: {
      handler(value) {
        if (!value) {
          this.searchBeginNumber();
        }
      },
    },
    selectedMsisdn: {
      handler(value) {
        this.$emit("selectedMsisdn", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";

.new-number {
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.input-wrapper {
  max-width: 42rem;
}
.number-input-container {
  margin-bottom: $telia-spacing-12;
}
.chip-choice-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $telia-spacing-12;
}
.chip-choice-item {
  margin-right: $telia-spacing-8;
  margin-bottom: $telia-spacing-4;
}
</style>
