<template>
  <div>
    <div
      :class="`collapser ${selected ? 'active' : 'closed'}`"
      tabindex="0"
      @click="handleExpand"
      @keydown.enter="handleExpand"
    >
      <div class="collapsed">
        <p class="title">{{ title }}</p>
        <p>{{ subtitle }}</p>
      </div>
      <div :class="`body ${selected ? 'body__active' : 'body__closed'}`">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "collapser",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    selected: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    handleExpand() {
      if (this.selected) return;
      this.$emit("expandCollapser", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.collapser {
  box-sizing: border-box;
  padding: 0 $telia-spacing-12 $telia-spacing-12 $telia-spacing-12;
  margin-bottom: $telia-spacing-12;
  border: solid 2px transparent;
  border-radius: 3px;
  background-color: $telia-white;
  transition: all 0.2s ease-in;
  overflow: hidden;
}
.collapser:hover {
  box-sizing: border-box;
  border: solid 2px $telia-purple-300;
}
.collapsed {
  padding: $telia-spacing-12 $telia-spacing-8 0 $telia-spacing-8;
}
.closed:hover {
  cursor: pointer;
}
.active {
  border: solid 2px $telia-purple-500;
  max-height: 100rem;
  cursor: default;
  overflow: visible;
}
.title {
  font-weight: 700;
  line-height: 2.4rem;
}
.body {
  z-index: 99;
  &__active {
    display: block;
    padding: $telia-spacing-12 $telia-spacing-8 0 $telia-spacing-8;
    margin-top: $telia-spacing-4;
  }
  &__closed {
    display: none;
  }
}
</style>
