<template>
  <section t-id="product-configuration-user-info" class="user-info">
    <div>
      <telia-p>
        {{ t("SECRET_NUMBER_INFO") }}
      </telia-p>
    </div>
    <telia-text-input
      :label="t('USER_FULLNAME')"
      :value="user.name"
      @input="setName($event.target.value)"
      required
      :required-error-message="t('FIELD_REQUIRED')"
      maxlength="40"
      :maxlength-error-message="t('MAX_LENGTH')"
      :error-message="inputErrors.name ? t('SPECIAL_CHAR_NOT_ALLOWED') : undefined"
    />
    <telia-checkbox
      :value="!useNameAsReference"
      :checked="useNameAsReference"
      @change="useNameAsReference = !useNameAsReference"
    >
      {{ t("USE_USER_AS_SUBSCRIPTION_REFERENCE") }}
    </telia-checkbox>
    <telia-text-input
      :label="t('USER_SUBSCRIPTION_REFERENCE')"
      :required-error-message="t('FIELD_REQUIRED')"
      :readonly="useNameAsReference"
      :value="user.reference"
      @input="setReference($event.target.value)"
      required
      :error-message="inputErrors.reference ? t('SPECIAL_CHAR_NOT_ALLOWED') : undefined"
    />
  </section>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import { translateMixin } from "../locale";

export default {
  name: "product-configuration-user-info",
  mixins: [translateMixin],
  props: {
    selectedCustomer: {
      type: Object,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      useNameAsReference: false,
      user: {
        name: "",
        reference: "",
      },
      inputErrors: {
        name: false,
        reference: false,
      },
    };
  },
  mounted() {
    this.emitValidity(this.valid);
  },
  computed: {
    valid() {
      return !this.v$.$invalid;
    },
  },
  methods: {
    setName(name) {
      this.inputErrors.name = false;
      const isValidInput = this.validateLatinChars(name);
      if (!isValidInput) {
        this.inputErrors.name = true;
        return;
      }
      this.user.name = name;
    },
    setReference(reference) {
      this.inputErrors.reference = false;
      const isValidInput = this.validateLatinChars(reference);
      if (!isValidInput) {
        this.inputErrors.reference = true;
        return;
      }
      this.user.reference = reference;
    },
    validateLatinChars(input) {
      try {
        // Try encoding the input string using btoa() which expects only Latin1 characters (1 byte per character).
        btoa(input);
        return true;
      } catch {
        return false;
      }
    },
    emitValidity(valid) {
      this.$emit("valid", valid);
    },
  },
  validations: {
    user: {
      name: {
        required,
        maxLength: maxLength(40),
      },
      reference: {
        required,
      },
    },
  },
  watch: {
    useNameAsReference: function (val) {
      if (val) {
        this.user.reference = this.user.name;
      } else {
        this.user.reference = "";
      }
    },
    user: {
      deep: true,
      handler(user) {
        this.$emit("setUser", user);
      },
    },
    "user.name"(name) {
      if (this.useNameAsReference) {
        this.user.reference = name;
      }
    },
    valid: "emitValidity",
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/spacing/variables";
@import "node_modules/@teliads/components/foundations/colors/variables";

.fade-enter-active,
.fade-leave-active {
  transition: opacity 150ms, transform 200ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(calc(var($telia-spacing-12) * -1));
}

.user-info {
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;
  padding: $telia-spacing-24 $telia-spacing-16 $telia-spacing-48 $telia-spacing-16;
  background-color: $telia-white;
  border: solid 2px $telia-purple-500;
}
.user-info:hover {
  border: solid 2px $telia-purple-300;
}

.delivery-info {
  margin-bottom: $telia-spacing-12;

  &__title {
    margin-bottom: $telia-spacing-12;
  }
}
.page-alert-wrapper {
  margin-top: $telia-spacing-12;
  margin-bottom: $telia-spacing-16;
}
</style>
