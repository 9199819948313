export default {
  methods: {
    formatMsisdn(msisdn = "") {
      if (msisdn) {
        switch (msisdn.length) {
          case 11:
            return msisdn.replace(/^[4][6](\d{2})(\d{3})(\d{2})(\d{2})$/, "0$1-$2 $3 $4");
          case 15:
            return msisdn.replace(/^[4][6](\d{2})(\d{3})(\d{3})(\d{3})(\d{2})$/, "0$1-$2 $3 $4 $5");
          default:
            return "";
        }
      } else {
        return "";
      }
    },
    formatToMsisdn(number = "") {
      return number
        ? number.replace(/^07/, "467").replace(/^\+/, "").replace(/^00/, "").replace(/[\s-]/g, "")
        : "";
    },
  },
};
