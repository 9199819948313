<template>
  <div
    :id="id"
    :class="`accordion-item ${open ? 'accordion-item__active' : 'accordion-item__closed'} ${
      showOverflow ? 'show-overflow' : ''
    }`"
  >
    <div @click="handleExpand" @keyup.enter="handleExpand" class="label-wrapper" tabIndex="0">
      <div class="heading">
        <telia-heading tag="h2" variant="title-100">{{ title }}</telia-heading>
        <div v-if="showCaret" :class="`${open ? 'chevron' : 'chevron__open'}`">
          <telia-icon size="md" name="chevron-up" />
        </div>
      </div>
      <div :class="`subtitle ${open ? 'subtitle__active' : 'subtitle__closed'}`">
        <slot name="subtitle" />
      </div>
    </div>
    <div :class="`body ${open ? 'body__active' : 'body__closed'}`">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: "accordion-item",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showCaret: {
      type: Boolean,
      default: true,
    },
    showOverflow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleExpand() {
      if (this.disabled) return;
      this.$emit("expand", this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "node_modules/@teliads/components/foundations/colors/variables";
@import "node_modules/@teliads/components/foundations/spacing/variables";

.accordion-item {
  padding: 0 $telia-spacing-16;
  transition: all 0.2s ease-in;
  border-bottom: solid 1px $telia-gray-300;
  overflow: hidden;
  &__closed {
    background-color: $telia-white;
  }
  &__active {
    background-color: $telia-gray-50;
  }
}
.accordion-item:hover {
  cursor: pointer;
}
.label-wrapper {
  padding: $telia-spacing-12 0;
}
.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.subtitle {
  transition: transform 0.2s;
  transition-timing-function: ease-in;
  transform: translateZ(0);
  &__active {
    display: none;
    transform: translateY($telia-spacing-8) translateZ(0);
  }
  &__closed {
    display: block;
  }
}
.chevron {
  transition: transform 0.3s ease;
  &__open {
    transform: rotateX(180deg);
  }
}
.body {
  overflow: hidden;
  transition: all 0.25s ease-in;

  &__active {
    overflow: visible;
    display: block;
    max-height: 100vh;
    padding-bottom: $telia-spacing-12;
    padding-top: $telia-spacing-12;
    margin-top: $telia-spacing-4;
    cursor: default;
  }
  &__closed {
    max-height: 0;
    visibility: hidden;
  }
}
.show-overflow {
  overflow: visible;
}
</style>
